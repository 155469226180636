/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';
import { Input, Col, Form, Checkbox, Row, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../../context/AppContext';
import Telephone from '../../../Telephone/Telephone';

/**
 * PersonalInformationsFieldset
 * @param {object} resetEmailField
 * @param {boolean} showCustomerForm
 * @return {fieldset}
 */
function PersonalInformationsFieldset({ form, resetEmailField, userFetch, previousEmailValue }) {
  const { t } = useTranslation();
  const [store, dispatch] = useContext(AppContext);

  const mailCustomerLogic = (e) => {
    resetEmailField();
    dispatch({ type: 'CREATE_DELIVERY_CUSTOMER', payload: {
      generateMail: e.target.checked,
    }});
  };

  return (
    <Card title={t('PERSONAL_INFORMATIONS')}>
      <Row gutter={{ md: 46 }}>
        <Col xs={24} md={12}>
          <Form.Item
            name='firstName'
            label={t('FIRST_NAME')}
            rules={!store.delivery.customer.isExisting && [{
              required: true,
              message: t('FIRST_NAME_REQUIRED'),
            }, {
              pattern: /^[^\d]*$/g,
              message: t('NUMBER_NOT_ALLOWED'),
            }, {
              pattern: /^(?=.{1,100}$).*/g,
              message: t('FIRST_NAME_LIMITE_LENGTH'),
            }]}
            initialValue={store.delivery.customer.firstName}
          >
            <Input id='first-name-step1' name='firstName' autoFocus disabled={store.delivery.customer.isExisting}/>
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name='lastName'
            label={t('NAME')}
            rules={!store.delivery.customer.isExisting && [{
              required: true,
              message: t('NAME_REQUIRED'),
            }, {
              pattern: /^[^\d]*$/g,
              message: t('NUMBER_NOT_ALLOWED'),
            }, {
              pattern: /^(?=.{1,100}$).*/g,
              message: t('LAST_NAME_LIMITE_LENGTH'),
            }]}
            initialValue={store.delivery.customer.lastName}
          >
            <Input id='last-name-create-step1' name='lastName' disabled={store.delivery.customer.isExisting}/>
          </Form.Item>
        </Col>
        <Telephone form={form} initialValue={store.delivery.customer.telephone} disabled={store.delivery.customer.isExisting} required={true}/>
        <Col xs={24} md={12}>
          <Form.Item
            name='email'
            label={t('EMAIL')}
            rules={[{
              type: 'email',
              message: t('WRONG_FORMAT_EMAIL'),
            }, {
              required: !store.delivery.customer.generateMail,
              message: t('EMAIL_REQUIRED'),
            }, () => ({
              validator(_, value) {
                if (!store.delivery.customer.isExisting) {
                  if (value && value === previousEmailValue && !userFetch?.loading) {
                    if (userFetch?.response?.status === 409) {
                      return Promise.reject(new Error(t('EMAIL_ALREADY_USED')));
                    }
                  }
                }
                return Promise.resolve();
              },
            })]}
            initialValue={store.delivery.customer.email}
            css={!store.delivery.customer.isExisting ? { marginBottom: 0 } : {}}
          >
            <Input id='email-create-step1' name='email' disabled={store.delivery.customer.generateMail || store.delivery.customer.isExisting}/>
          </Form.Item>
          {!store.delivery.customer.isExisting &&
            <Form.Item name='generateMail'>
              <Checkbox
                id='checkbox-generate-mail-create-step1'
                onChange={mailCustomerLogic}
                name='generateMail' checked={store.delivery.customer.generateMail}
                disabled={store.delivery.customer.isExisting}>
                {t('NO_MAIL_CUSTOMER')}
              </Checkbox>
            </Form.Item>
          }
        </Col>
      </Row>
    </Card>
  );
}
export default PersonalInformationsFieldset;
