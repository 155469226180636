import { Skeleton, Collapse } from 'antd';
import { customizeRenderEmpty } from '../../services/customizeRenderEmpty';

const { Panel } = Collapse;
/**
 * SkeletonMobile
 * @param {boolean} loading
 * @param {JSX.Element} children
 * @param {number} rowCount
 * @param {boolean} emptyState
 * @param {boolean} emptyError
 * @param {function} refreshLink
 * @param {function} updateUserSearch
 * @return {Array<JSX.Element>}
 */
function SkeletonMobile( { loading, children, rowCount, emptyState, emptyError, refreshLink, updateUserSearch }) {
  const SkeletonHeader = () => {
    return (
      <Skeleton.Button
        style={{
          width: '115px',
        }}
        active={true}
        size={'small'}
      />
    );
  };

  const SkeletonExtra = () => {
    return (
      <Skeleton.Button
        style={{
          width: '115px',
        }}
        active={true}
        size={'small'}
      />
    );
  };


  return loading ? (
    <Collapse accordion defaultActiveKey={0} activeKey={0}>
      {[...Array(rowCount)].map((_, i) => {
        return (
          <Panel
            key={i}
            header={<SkeletonHeader />}
            extra={<SkeletonExtra />}
          >
            <Skeleton active={true}/>
          </Panel>
        );
      })}
    </Collapse>
  ) : (
    <Collapse style={{ padding: 20 }}>
      {emptyState ? customizeRenderEmpty({ customizeEmpty: emptyError, refreshLink: refreshLink, updateUserSearch: updateUserSearch }) : children}
    </Collapse>
  );
}

SkeletonMobile.defaultProps = {
  loading: false,
  active: true,
  rowCount: 5,
};
export default SkeletonMobile;

