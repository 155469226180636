import React, { useState, useEffect, useContext } from 'react';
import { Input, Select, Col, Row, Button, Drawer, Space, AutoComplete, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import useFetch from 'use-http';
import AddressForm from '../Address/AddressForm';
import DrawersContext from '../../context/DrawersContext';
import countries from '../../i18n/countries.json';
import useDebounce from '../../hooks/useDebounce';
import HighlightText from '../HighlightText/HighlightText';
import { NotificationCenterContext } from '../../context/NotificationCenterContext';

/**
 * NewAddressDrawer
 * @param {number} userId
 * @param {function} createAddressCallback
 * @return {Drawer}
 */
function NewAddressDrawer({ userId, createAddressCallback }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const newAddressFetch = useFetch(process.env.REACT_APP_API_BO);
  const visible = useContext(DrawersContext)['visible'];
  const setVisible = useContext(DrawersContext)['setVisible'];
  const setCurrentDrawer = useContext(DrawersContext)['setCurrentDrawer'];
  const notification = useContext(NotificationCenterContext);

  const countriesStorage = localStorage.getItem('CountriesFilter')?.split(',');
  const countriesList = countriesStorage ? countriesStorage.map((countryStorage) => {
    return countries.find((country) => country.alpha2 === countryStorage);
  }) : countries;

  const [optionsFoundAddresses, setOptionsFoundAddresses] = useState([]);
  const [selectedAlpha2, setSelectedAlpha2] = useState(countriesList.length === 1 ? countriesList[0].alpha2 : null);
  const [searchAddressTerm, setSearchAddressTerm] = useState(null);
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [disabledSaveButton, setDisabledSaveButton] = useState(false);
  const [geolocId, setGeolocId] = useState('');
  const debouncedSearchTerm = useDebounce(searchAddressTerm, 500);

  const searchResult = ({ addresses, search }) => {
    return addresses.map((address) => {
      return {
        value: JSON.stringify(address),
        label: (
          <div>
            <HighlightText
              text={address.address}
              highlightTag={search}
            />
          </div>
        ),
      };
    });
  };

  const getNewAddress = async() => {
    await newAddressFetch.get(`/addresses/autocomplete?q=${debouncedSearchTerm}&locale=fr&country=${selectedAlpha2}`);
    if (newAddressFetch.response.ok) {
      setOptionsFoundAddresses(newAddressFetch.response.data.length > 0 ? searchResult({ addresses: newAddressFetch.response.data, search: debouncedSearchTerm }) : []);
    } else {
      if (newAddressFetch.response.status !== 404) {
        notification.push('error', t('INTERNAL_ERROR'));
      } else if (newAddressFetch.response.status === 404) {
        setOptionsFoundAddresses([]);
      }
    }
  };

  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm?.length >= 3) {
      getNewAddress();
    }
  }, [debouncedSearchTerm, selectedAlpha2]);

  const onSelectFoundAddresses = (value) => {
    const addressData = JSON.parse(value);
    form.setFieldsValue({ address: addressData.address.slice(0) });
    setGeolocId(addressData.placeId);
    setShowAddressForm(true);
  };

  const handleAddressSearch = async(value) => {
    if (value.length === 0) {
      setOptionsFoundAddresses([]);
      setShowAddressForm(false);
    } else {
      setSearchAddressTerm(value);
    }
  };

  const onFinish = async(value) => {
    const address = {
      additional_info: value?.additional_info,
      address: value?.address,
      apartment: value?.apartment,
      country: value?.country,
      floor: value.floor,
      digicode: value.digicode,
      user_id: userId,
      zip_code: '',
      city: '',
      placeId: geolocId,
      elevator: value.elevator,
    };
    createAddressCallback(address);
    form.resetFields();
    setVisible(false);
    setCurrentDrawer(null);
  };

  const onClose = () => {
    setVisible(false);
    setCurrentDrawer(null);
  };

  return (
    (<Drawer
      title={t('ADD_AN_ADDRESS')}
      placement="right"
      open={visible}
      onClose={onClose}
      width={window.innerWidth <= 630 ? '100%' : 630}
      footer={
        <Space>
          <Button
            type="primary"
            onClick={() => form.submit()}
            disabled={!form.getFieldValue('address') || disabledSaveButton || !showAddressForm}
            id='drawers-new-address-button-save'
          >
            {t('SAVE')}
          </Button>
        </Space>
      }
    >
      <Form
        name="CreateDeliveryForm"
        form={form}
        layout="vertical"
        initialValues={{}}
        onFieldsChange={() => setDisabledSaveButton(form.getFieldsError().filter(({ errors }) => errors.length).length > 0)}
        onFinish={onFinish}
      >
        <Row>
          <Col xs={24}>
            <Form.Item
              label={t('COUNTRY')}
              name="country"
              rules={[{ required: true, message: t('COUNTRY_REQUIERED') }]}
              initialValue= {countriesList.length === 1 ? countriesList[0].alpha2 : null}
            >
              <Select
                style={{ width: '100%' }}
                placeholder={t('SELECT_A_COUNTRY')}
                onSelect={(value) => setSelectedAlpha2(value)}
                id='select-country-new-address'
                options={countriesList.map((country) => {
                  return ({
                    value: country.alpha2,
                    label: country.name,
                  });
                })}
                disabled={countriesList.length === 1}
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              label={t('SEARCH_AN_ADDRESS')}
              name="address"
            >
              <AutoComplete
                options={optionsFoundAddresses}
                onSelect={onSelectFoundAddresses}
                onSearch={handleAddressSearch}
                style={{ width: '100%' }}
                disabled={!selectedAlpha2}
              >
                <Input.Search
                  placeholder={t('TYPE_YOUR_SEARCH')}
                  loading={newAddressFetch.loading}
                  id='address-new-address'
                />
              </AutoComplete>
            </Form.Item>
          </Col>
          {
            showAddressForm &&
            <AddressForm />
          }
        </Row>
      </Form>
    </Drawer>)
  );
}

export default NewAddressDrawer;
