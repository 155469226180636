import { apiEndPointsOptions } from './apiEndPointsOptions';

/**
 * Fetch data.
 * @param {string} apiUrl
 * @param {string} method
 * @param {object} signal
 * @return {object}
 */
async function fetchData({ apiUrl, method, signal }) {
  const response = await fetch(
    apiUrl,
    await apiEndPointsOptions(method, undefined, signal)
  );

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return {
    data: await response.json(),
    status: response.status,
    ok: response.ok,
  };
}

export default fetchData;
