/** @jsxImportSource @emotion/react */
import { List, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;
/**
 * ItemAdress
 * @param {object} address
 * @param {object} addressErrorsInfos
 * @return {JSX.Element}
 */
function ItemAdress({ address, addressErrorsInfos }) {
  const { t } = useTranslation();
  const viewAddress = () => {
    if (address.id && address.original_address) {
      return <> {address.original_address}, {address.country} </>;
    }
    if (address.id && !address.original_address) {
      return <> {address.address}, {address.zip_code} {address.city} </>;
    } else {
      return <> {address.address} </>;
    }
  };
  const isDistanceBetweenDriveAndDeliveryAdress = (addressErrorsInfos?.message && addressErrorsInfos.address_id === address.id );

  return (
    <>
      {isDistanceBetweenDriveAndDeliveryAdress ?
        <List.Item.Meta
          title={
            <Text type='danger'>{address && viewAddress()}</Text>
          }
          description={
            <Text type='danger'>{t(addressErrorsInfos?.message)}</Text>
          }
        /> :
        <List.Item.Meta
          title={<Text>{address && viewAddress()}</Text>}
        />}
    </>
  );
}

export default ItemAdress;
