/** @jsxImportSource @emotion/react */
import { forwardRef, useImperativeHandle } from 'react';
import { Form, Radio, Input, Select, Switch, InputNumber, Checkbox, AutoComplete, Col, Row } from 'antd';
import RadioCard from '../components/RadioCard';
import { useTranslation } from 'react-i18next';
import countries from '../i18n/countries.json';
import useCustomHtml from '../hooks/useCustomHtml';
import processedValidationRules from './processedValidationRules ';

/**
 * DrawForm
 * @param {string} formName
 * @param {object} initialValues // { key: value }
 * @param {array} formItems // [{ id, input_type, name, label, rules, initialValue, placeholder, onChange, options }]
 * @param {function} onFinish
 * @return {JSX.Element}
 */
const DrawForm = forwardRef(function DrawForm(props, ref) {
  const { t } = useTranslation();
  const { form, formName, initialValues, formItems, onFinish, handleCotransporterProblemChange } = props;

  useImperativeHandle(ref, () => ({
    submit() {
      form.submit();
    },
    reset() {
      form.resetFields();
    },
  }));

  const apartmentLengthErrorMessage = useCustomHtml({
    keyValues: [
      { key: '[LABEL]', value: t('APARTMENT') },
    ],
    inputString: t('INPUT_NUMBER_LIMITE_LENGTH'),
  });

  const digicodeLengthErrorMessage = useCustomHtml({
    keyValues: [
      { key: '[LABEL]', value: t('DIGICODE') },
    ],
    inputString: t('INPUT_NUMBER_LIMITE_LENGTH'),
  });

  const flexForm = ['apartment', 'digicode', 'floor'];

  return (
    <Form
      name={formName}
      form={form}
      onFinish={onFinish}
      initialValues={initialValues}
      layout="vertical"
    >
      <Row gutter={[10, 0]}>
        {formItems?.map((item) => {
          const {
            id,
            inputType,
            name,
            label,
            rules,
            options,
            cssProps,
            showCount,
            maxLength,
            variations, // optional, use for example in radio-card component to change layout direction (horizontal, vertical)
            placeholder,
            disabled,
            message,
          } = item;

          return (
            <Col xs={24} md={flexForm.includes(name) ? { span: 8 } : { span: 24 }} >
              <Form.Item
                key={id}
                name={name}
                label={t(label)}
                rules={processedValidationRules({
                  rules,
                  t,
                  apartmentLengthErrorMessage,
                  digicodeLengthErrorMessage,
                })}
                valuePropName={inputType === 'switch' || inputType === 'checkbox' ? 'checked' : 'value'}
                css={{
                  '.ant-row': variations?.forceHorizontalLayout ? {
                    alignItems: 'center',
                    flexDirection: 'row',
                    gap: 20,
                  } : {},
                  '.ant-row > div': variations?.forceHorizontalLayout ? {
                    width: 'fit-content !important',
                    padding: 0,
                    flex: 'unset !important',
                  } : {},
                }}
                htmlFor={`${formName}-${name}`}
              >
                {(() => {
                  switch (inputType) {
                  case 'input-money':
                    return (
                      <InputNumber
                        controls={true}
                        min={0}
                        step={0.5}
                        style={{ width: '100%' }}
                        addonAfter="€"
                        keyboard={true}
                        id={`${formName}-${name}`}
                      />
                    );
                  case 'input-number':
                    return (
                      <InputNumber
                        controls={true}
                        min={0}
                        style={{ width: '100%' }}
                        id={`${formName}-${name}`}
                      />
                    );
                  case 'input-text':
                    return (
                      <Input onChange={() => {}} id={`${formName}-${name}`} />
                    );
                  case 'radio-card':
                    return (
                      <Radio.Group
                        onChange={(e) => {
                          handleCotransporterProblemChange(e.target.value);
                        }}
                        style={{
                          minWidth: '100%',
                        }}
                        id={`${formName}-${name}`}
                      >
                        <RadioCard cssProps={cssProps} variations={variations} id={`${formName}-${name}`}>
                          {options.map((option) => (
                            <Radio key={option.value} value={option.value}>
                              {t(option.label)}
                            </Radio>
                          ))}
                        </RadioCard>
                      </Radio.Group>
                    );
                  case 'select':
                    return (
                      <Select disabled={disabled} id={`${formName}-${name}`}>
                        {options === 'deliveryCountries' ?
                          countries.map((country) => (
                            <Select.Option key={country.alpha2} value={country.alpha2}>
                              {t(country.name)}
                            </Select.Option>
                          )) :

                          options.map((option) => (
                            <Select.Option key={option.value} value={option.value}>
                              {t(option.label)}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    );
                  case 'autoComplete':
                    return (
                      <AutoComplete style={{ width: '100%' }} disabled={disabled} id={`${formName}-${name}`}>
                        <Input.Search
                          placeholder={placeholder}
                        />
                      </AutoComplete>
                    );
                  case 'textarea':
                    return (
                      <Input.TextArea
                        showCount={showCount}
                        maxLength={maxLength}
                        id={`${formName}-${name}`}
                      />
                    );
                  case 'switch':
                    return (
                      <Switch id={`${formName}-${name}`}/>
                    );
                  case 'checkbox':
                    return (
                      <Checkbox onChange={() => {}} id={`${formName}-${name}`}/>
                    );
                  case 'text':
                    return (
                      <p style={cssProps}>{t(message)}</p>
                    );
                  default:
                    throw new Error('No input type matching.');
                  }
                })()}
              </Form.Item>
            </Col>
          );
        })
        }
      </Row>

    </Form>
  );
});

export default DrawForm;
