import { ReactNode } from 'react';
import { Card, CardProps } from 'antd';
import styled from '@emotion/styled';

interface OnlyTitleCardProps {
    card: CardProps;
}

function OnlyTitleCard({ card }: OnlyTitleCardProps): ReactNode {
  return (
    <StyledOnlyTitleCard>
      <Card {...card} />
    </StyledOnlyTitleCard>
  );
}

const StyledOnlyTitleCard = styled.div`
    .ant-card {
        .ant-card-head {
          border-bottom: 0 !important;
        }
        .ant-card-body {
            padding: 0 !important;
            display: none !important;
        }
    }
`;

export default OnlyTitleCard;
