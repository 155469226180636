import { Image } from 'antd';
import { Link } from 'react-router-dom';

interface SideMenuLogoProps {
  logo: string;
  logoSM: string;
  small: boolean;
}

/**
 * SideMenuLogo component
 */
function SideMenuLogo({ logo, logoSM, small }: SideMenuLogoProps) {
  return (
    <Link
      to={'/'}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50px',
        padding: '50px 0',
      }}
    >
      <div
        style={{
          opacity: Number(!small),
          position: 'absolute',
          padding: '0 40px',
          transition: 'opacity 0.3s',
        }}
      >
        <Image
          src={logo}
          alt="side menu logo"
          preview={false}
          style={{
          }}
        />
      </div>
      <div
        style={{
          opacity: Number(small),
          position: 'absolute',
          padding: '0 10px',
          transition: 'opacity 0.3s',
        }}
      >
        <Image
          src={logoSM}
          alt="side menu logo"
          preview={false}
          style={{
          }}
        />
      </div>
    </Link>
  );
}

export default SideMenuLogo;
