/** @jsxImportSource @emotion/react */
import { Row, Col, Space } from 'antd';
import { breakpoints } from '../../globalVar/theme';

/**
 * LoginLayout
 * @param {*} children
 * @return {JSX.Element}
 */
function LoginLayout({ children }) {
  return (
    <Row css={{ height: '100vh', backgroundColor: 'white' }}>
      <Col
        xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 11 }}
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          padding: '65px 0px 0px',
          [breakpoints.lg]: {
            alignItems: 'start',
            padding: '65px 64px 0px',
          },
        }}
      >
        <img src={process.env.PUBLIC_URL + '/assets/logo-shopopop.svg'} alt="ShopopopLogo" />
        <Row justify='center' css={{ width: '100%' }}>
          {children}
        </Row>
      </Col>
      <Col
        xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 0 }} lg={{ span: 13 }}
        css={{ backgroundColor: '#FDFAF3' }}
      >
        <Space css={{ width: '100%', height: '100vh', justifyContent: 'center' }} direction='vertical' align='center'>
          <img src={process.env.PUBLIC_URL + '/assets/pc-bo-login.svg'} alt="ShopopopBoPicto"/>
        </Space>
      </Col>
    </Row>
  );
}

export default LoginLayout;
