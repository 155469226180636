import { Layout } from 'antd';
import styled from '@emotion/styled';
import { HTMLAttributes } from 'react';

interface StyledLayoutProps {
  mobilemode: string;
  sidercollapsed: string;
  backgroundcolor: string;
}

const StyledLayout = styled(({ ...rest }: StyledLayoutProps & HTMLAttributes<HTMLElement>) => <Layout {...rest} />)`
    display: flex;
    flex-direction: column;
    padding: ${(props) => props.mobilemode === 'true' ? '0 16px' : '0 24px'};
    margin-left: ${(props) => props.mobilemode === 'true' ? 0 : (props.sidercollapsed === 'true' ? '80px' : '256px')};
    transition: margin-left 0.2s;
    &::before {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        width: ${(props) => props.sidercollapsed === 'false' && props.mobilemode === 'true' ? '100%' : 0};
        height: 100%;
        background-color: ${(props) => props.backgroundcolor};
        transition: opacity 0.2s;
        opacity: ${(props) => props.sidercollapsed === 'false' && props.mobilemode === 'true' ? 0.4 : 0};
        z-index: ${(props) => props.sidercollapsed === 'false' && props.mobilemode === 'true' ? 100 : 0};
    }
`;

export default StyledLayout;
