import { Tooltip, Typography, theme } from 'antd';

const { Text } = Typography;

function DetailWithPopoverTuple({ primaryInfo, hoverInfo }: {primaryInfo: string, hoverInfo: string}) {
  const { token: { colorPrimaryText }} = theme.useToken();

  return (
    <Tooltip
      title={hoverInfo}
      placement="bottom"
      color={colorPrimaryText}
    >
      <Text>{primaryInfo}</Text>
    </Tooltip>
  );
}

export default DetailWithPopoverTuple;
