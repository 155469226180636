import { ReactNode } from 'react';
import { Button, Layout, Menu, theme } from 'antd';
import {
  LeftOutlined,
  RightOutlined,
  MenuOutlined, PlusOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useScreenSize, useMenuItems } from '@shopopop/react-hooks';

const { Sider } = Layout;

export interface SideMenuProps {
  menuItems: any;
  logoComponent: ReactNode;
  siderCollapsed: boolean;
  setSiderCollapsed: any;
  onLogout: any;
  createFormUrl?: string;
}

/**
 * SideMenu component
 */
function SideMenu({ menuItems, logoComponent, siderCollapsed, setSiderCollapsed, onLogout, createFormUrl }: SideMenuProps): ReactNode {
  const { t } = useTranslation();
  const { antdMenuItems, activeKey } = useMenuItems({ menuItems, onLogout });
  const { mobileMode, width } = useScreenSize();
  const { token } = theme.useToken();

  // Close the mobile menu when clicking on a menu item
  const collapseMobileMenu = () => {
    if (mobileMode) {
      setSiderCollapsed(true);
    }
  };

  return (
    <Sider
      collapsible
      breakpoint="xs"
      width={256}
      collapsedWidth={width <= token.screenXS ? 0 : 80}
      collapsed={siderCollapsed}
      onCollapse={(value) => setSiderCollapsed(value)}
      trigger={<SideMenuTrigger siderCollapsed={siderCollapsed} setSiderCollapsed={setSiderCollapsed} />}
      style={{
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        zIndex: 1000,
      }}
    >
      <div onClick={collapseMobileMenu}>
        {logoComponent}
      </div>
      <Link to={createFormUrl ?? '/delivery'}>
        <CreateDeliveryStyledWrapper>
          <Button
            type='primary'
            size='large'
            style={{
              fontSize: '14px',
              width: !siderCollapsed ? '215px' : 'auto',
              minWidth: '48px',
              borderRadius: '32px',
              maxWidth: '215px',
              overflow: 'hidden',
              transition: 'all 0s',
              // @ts-ignore - va disparaître avec le prochain thème où on utilisera simplement la couleur Primary
              backgroundColor: token['princpalSideMenuButtonBgColor'],
            }}
            shape={siderCollapsed ? 'circle' : 'round'}
            icon={<PlusOutlined style={siderCollapsed ? { lineHeight: '0px' } : {}}/>}
            onClick={collapseMobileMenu}
            name='delivery'>
            {!siderCollapsed && t('MENU_NEW_DELIVERY')}
          </Button>
        </CreateDeliveryStyledWrapper>
      </Link>
      <Menu
        defaultSelectedKeys={[activeKey]}
        mode="inline"
        items={antdMenuItems}
        onClick={collapseMobileMenu}
      />
    </Sider>
  );
}

interface SideMenuTriggerProps {
  siderCollapsed: boolean;
  setSiderCollapsed: any;
}

/**
 * SideMenuTrigger component
 */
function SideMenuTrigger({ siderCollapsed, setSiderCollapsed }: SideMenuTriggerProps): ReactNode {
  const { token } = theme.useToken();
  const { width } = useScreenSize();

  return width <= token.screenXS ? (
    <div style={{ backgroundColor: token.colorPrimaryBg }}>
      <MenuOutlined style={{ color: token.colorPrimary }}/>
    </div>
  ) : (
    <div
      onClick={() => setSiderCollapsed(!siderCollapsed)}
      style={{
        cursor: 'pointer',
        color: token?.Menu?.itemColor,
        backgroundColor: token?.Menu?.itemBg,
      }}
    >
      {siderCollapsed ? <RightOutlined/> : <LeftOutlined/>}
    </div>
  );
}

const CreateDeliveryStyledWrapper = styled.div`
    text-align: center;
    margin: 0 0 20px;
  `;

export default SideMenu;
