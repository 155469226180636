/** @jsxImportSource @emotion/react */
import { useContext, useEffect, useState } from 'react';
import { Skeleton, Card, Space, Typography } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useRouter } from '../../hooks/useRouter';
import { useFetch } from 'use-http';
import { AppContext } from '../../context/AppContext';
import { findAddress } from '../../utils/findAddress';
import ListAddresses from './ListAddresses';
import ListRadioAddresses from './ListRadioAdresses';
import CreateAddressButton from './CreateAddressButton';
import { colors } from '../../globalVar/theme';

const { Text } = Typography;

/**
 * CardAdresses
 * @param {number} statusError
 * @param {number} userId
 * @param {object} deliveryAddress
 * @param {function} setDeliveryAddress
 * @param {array} visibleAddresses
 * @param {function} setVisibleAddresses
 * @param {number} indexValue
 * @param {function} setIndexValue
 * @param {function} showSaveBarCallback
 * @param {boolean} step1
 * @param {object} addressErrorsInfos
 * @return {JSX.Element}
 */
function CardAdresses({
  statusError,
  userId,
  deliveryAddress,
  setDeliveryAddress,
  visibleAddresses,
  setVisibleAddresses,
  indexValue,
  setIndexValue,
  showSaveBarCallback,
  step1,
  addressErrorsInfos,
}) {
  const { t } = useTranslation();
  const router = useRouter();
  const [store, dispatch] = useContext(AppContext);
  const addressFetch = useFetch(process.env.REACT_APP_API_BO, {
    cachePolicy: 'no-cache',
  });
  const [visibleAddressesCount, setVisibleAddressesCount] = useState(2);
  const [addresses, setAddresses] = useState([]);
  const [detailAddressesList, setDetailAddressesList] = useState([]);
  const [updatedDeliveryList, setUpdatedDeliveryList] = useState([]);
  const [numberloadMore, setNumberloadMore] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const storeAddresses = store.delivery.customer.addresses;
  const storeAddress = store.delivery.customer.address;

  const userPage = router.pathname.includes('users');

  // #region récupération des adresses dans le store ou en BDD
  const getAddresses = async() => {
    await addressFetch.get(`/users/${userId}/addresses`);
    if (addressFetch.response.status === 200) {
      setAddresses(addressFetch.response.data);
    }
  };

  useEffect(() => {
    if (deliveryAddress || statusError === 200) {
      setIsLoading(false);
      if (addresses.length === detailAddressesList.length) {
        getAddresses();
      }
    } if (step1) {
      setIsLoading(false);
      if (storeAddresses.length > 0) {
        if (!storeAddress.address) {
          setAddresses(storeAddresses);
          setVisibleAddresses(storeAddresses.slice(0, visibleAddressesCount));
          setIndexValue(0);
          dispatch({ type: 'SET_CUSTOMER_SELECTED_ADDRESS', payload: addresses[indexValue] });
        } else {
          const deliverySelect = findAddress(storeAddresses, storeAddress.id);
          const newListAddresses = [
            ...storeAddresses.slice(0, deliverySelect),
            ...storeAddresses.slice(deliverySelect + 1),
          ];
          setAddresses([storeAddresses[deliverySelect], ...newListAddresses]);
          setVisibleAddresses(addresses.slice(0, visibleAddressesCount));
          setIndexValue(0);
        }
      } else {
        setAddresses([]);
        setVisibleAddresses([]);
      }
    }
  }, [statusError, deliveryAddress, storeAddresses]);
  // #endregion

  // Dans le détail de livraison, afficher l'adresse de la livraison en premier
  useEffect(() => {
    if (deliveryAddress && deliveryAddress.id) {
      const addressIndex = addresses.findIndex((element) => element.id === deliveryAddress.id);
      setIndexValue(0);
      if (addressIndex < 0) {
        setUpdatedDeliveryList([deliveryAddress, ...addresses]);
        setDetailAddressesList(updatedDeliveryList);
        setVisibleAddresses(updatedDeliveryList.slice(0, visibleAddressesCount));
      } else {
        setUpdatedDeliveryList([addresses[addressIndex], ...addresses.filter((address) => address.id !== deliveryAddress.id)]);
        setDetailAddressesList(updatedDeliveryList);
        setVisibleAddresses(detailAddressesList.slice(0, visibleAddressesCount));
      }
    }
  }, [addresses]);

  // Dans la création d'une livraison permet de modifier l'adresse choisie pour la livraison
  useEffect(() => {
    if (store.delivery.customer.addresses.length > 0) {
      dispatch({
        type: 'SET_CUSTOMER_SELECTED_ADDRESS',
        payload: visibleAddresses[indexValue],
      });
    }
  }, [indexValue]);

  useEffect(() => {
    setNumberloadMore(addresses.length > visibleAddressesCount ? addresses.length - visibleAddressesCount : 0);
    deliveryAddress ? setVisibleAddresses(updatedDeliveryList.slice(0, visibleAddressesCount)) : setVisibleAddresses(addresses.slice(0, visibleAddressesCount));
  }, [addresses, visibleAddressesCount]);

  // Dans le détail de livraison, afficher la liste des addresses une fois celes-ci chargées
  useEffect(() => {
    deliveryAddress && setVisibleAddresses(updatedDeliveryList.slice(0, visibleAddressesCount));
  }, [updatedDeliveryList, visibleAddressesCount]);

  const onLoadMore = () => {
    setVisibleAddressesCount(visibleAddressesCount + 5);
  };

  const loadMore =
    numberloadMore > 0 && (
      <Space
        onClick={() => onLoadMore()}
        css={{
          width: '100%',
          height: '60px',
          justifyContent: 'center',
          cursor: 'pointer',
          borderTop: '1px solid rgba(13, 55, 155, 0.12)',
        }}
      >
        <ReloadOutlined style={{ color: colors.blueMiddle }} />
        <Text type='secondary' underline>
          {t('LOAD_MORE_ADDRESSES')} ({numberloadMore})
        </Text>
      </Space>
    );

  return (
    <Card
      title='Adresses'
      extra={<CreateAddressButton
        userId={userId}
        statusError={statusError}
        setIndexValue={setIndexValue}
        setVisibleAddresses={setVisibleAddresses}
        visibleAddresses={visibleAddresses}
        setAddresses={setAddresses}
        addresses={addresses}
        deliveryAddress={deliveryAddress}
        setDeliveryAddress={setDeliveryAddress}
        showSaveBarCallback={showSaveBarCallback}
        visibleAddressesCount={visibleAddressesCount}
      />}
      bodyStyle={{ padding: !isLoading && 0 }}>
      {isLoading ? (
        <Skeleton />
      ) : userPage ? (
        <div className='card-addresses'>
          <ListAddresses
            loadMore={loadMore}
            addresses={addresses}
            setAddresses={setAddresses}
            visibleAddresses={visibleAddresses}
            setVisibleAddresses={setVisibleAddresses}
            visibleAddressesCount={visibleAddressesCount}
          /></div>
      ) : (
        <div className='card-addresses'>
          <ListRadioAddresses
            addressErrorsInfos={addressErrorsInfos}
            loadMore={loadMore}
            addresses={deliveryAddress ? detailAddressesList : addresses}
            setAddresses={deliveryAddress ? setDetailAddressesList : setAddresses}
            visibleAddresses={visibleAddresses}
            setVisibleAddresses={setVisibleAddresses}
            indexValue={indexValue}
            setIndexValue={setIndexValue}
            showSaveBarCallback={showSaveBarCallback}
            setDeliveryAddress={setDeliveryAddress}
            deliveryAddress={deliveryAddress}
            visibleAddressesCount={visibleAddressesCount}
            updatedDeliveryList={updatedDeliveryList}
            setUpdatedDeliveryList={setUpdatedDeliveryList}
          />
        </div>
      )}
    </Card>
  );
}

export default CardAdresses;
