/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Button, Col, Typography, Row } from 'antd';
import LoginLayout from '../LoginLayout';
import { CachePolicies, useFetch } from 'use-http';
import { NotificationCenterContext } from '../../../context/NotificationCenterContext';
import { useRouter } from '../../../hooks/useRouter';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { breakpoints } from '../../../globalVar/theme';
import { useNavigate } from 'react-router-dom';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import Turnstile from '../../Captcha/Turnstile';
import { AppContext } from '../../../context/AppContext';

const { Title, Text } = Typography;


/**
 * Forgot password
 * @return {JSX.Element}
 */
function ForgotPassword() {
  const { t } = useTranslation();
  useDocumentTitle(t('URL_RESET_PASSWORD'));
  const logger = useContext(NotificationCenterContext);
  const [state] = useContext(AppContext);
  const router = useRouter();
  const navigate = useNavigate();
  const [forgotForm] = Form.useForm();
  const { post, loading, response } = useFetch(process.env.REACT_APP_API_BO, { cachePolicy: CachePolicies.NO_CACHE });

  /**
   * onfinish
   * @param {Object} values
   */
  function onFinish(values) {
    post(`/auth/reset/${values.email}`).then(() => {
      if (response.ok) {
        logger.push('success', t('LOGIN_FORGOT_PASSWORD_SUCCESS_TITLE'), t('LOGIN_FORGOT_PASSWORD_SUCCESS_DESCRIPTION'));
        navigate('/settings');
      } else {
        logger.push('error', t('LOGIN_FORGOT_PASSWORD_ERROR'), '');
      }
    });
  }

  useEffect(() => {
    forgotForm.setFieldsValue({ email: router.query?.email });
  }, []);

  return (
    <LoginLayout>
      <Col
        css={{
          width: 420,
          margin: '70px 21px auto',
          [breakpoints.lg]: {
            margin: '120px auto auto',
          },
        }}
      >
        <Button
          id='back-forgot-password-button'
          type='link'
          icon={<ArrowLeftOutlined />}
          onClick={() => navigate('/settings')}
          css={{
            'span:last-child': {
              textDecoration: 'underline',
            },
          }}
        >
          {t('BACK_TO_SETTINGS')}
        </Button>
        <Col
          css={{
            textAlign: 'center',
            [breakpoints.lg]: {
              textAlign: 'start',
            },
          }}
        >
          <Title level='1'>{t('LOGIN_FORGOT_PASSWORD')}</Title>
          <Text>{t('LOGIN_FORGOT_PASSWORD_DESCRIPTION')}</Text>
        </Col>
        <Form
          layout="vertical"
          name="forgot-password"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onFinish={onFinish}
          autoComplete="on"
          form={forgotForm}
          css={{ width: '100%', marginTop: 20 }}
        >
          <Form.Item
            label={t('LOGIN_EMAIL')}
            name='email'
            validateTrigger="onBlur"
            rules={[{
              required: true,
              message: t('LOGIN_NO_EMAIL'),
            }, {
              type: 'email',
              message: t('LOGIN_BAD_EMAIL_FORMAT'),
            }]}
          >
            <Input allowClear size="large" placeholder="abc.def@shopopop.com" />
          </Form.Item>
          <Row justify='center'>
            <Form.Item wrapperCol={{ span: 16 }}>
              <Button type="primary" htmlType="submit" loading={loading} disabled={!state.captcha.value}>
                {t('VALIDATE')}
              </Button>
            </Form.Item>
          </Row>
          <Row>
            <Form.Item>
              <Turnstile/>
            </Form.Item>
          </Row>
        </Form>
      </Col>
    </LoginLayout>
  );
}

export default ForgotPassword;
