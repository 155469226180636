import { ReactNode } from 'react';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { ValidateStatusType } from '@shopopop/react-hooks';

interface InputEmailProps {
  name: string;
  label: string;
  dataTestid: string
  options?: {
    required?: boolean;
    disabled?: boolean;
    noEmailCheckbox?: boolean;
    validateStatus?: ValidateStatusType;
    help?: string;
  }
}

function InputEmail({
  name,
  label,
  dataTestid,
  options,
}: InputEmailProps): ReactNode {
  const { t } = useTranslation();

  const formItemProps: {
    validateStatus?: ValidateStatusType;
    help?: string;
  } = {};

  if (options?.validateStatus) {
    formItemProps.validateStatus = options.validateStatus;
  }
  if (options?.help) {
    formItemProps.help = options.help;
  }

  return (
    <Form.Item
      name={name}
      label={label}
      {...formItemProps}
      rules={options?.disabled ? [] : [
        {
          required: options?.required,
          message: t('EMAIL_REQUIRED'),
        },
        {
          type: 'email',
          message: t('WRONG_FORMAT_EMAIL'),
        },
      ]}
      style={options?.noEmailCheckbox ? {
        marginBottom: 5, // réduit l'espace entre le form item et la checbox si présente.
      } : {}}
    >
      <Input data-testid={dataTestid} disabled={options?.disabled} />
    </Form.Item>
  );
}

export default InputEmail;
