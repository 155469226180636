/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from 'react-country-flag';
import { Layout, Row, Col, Space, Typography, Flex } from 'antd';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import AuthService from '../../services/AuthService';
import './Login.css';
import LanguageSelector from '../Settings/LanguageSelector/LanguageSelector';
import countries from '../../i18n/countries';
import { useWindowSize } from '../../hooks/useWindowSize';
import { breakpoints, sizes } from '../../globalVar/theme';

const { Header, Content } = Layout;
const { Text, Title } = Typography;

/**
 * Login
 * @param {*} route
 * @return {string}
 */
function Login() {
  const { t } = useTranslation();
  const windowSize = useWindowSize();
  useDocumentTitle(t('URL_LOGIN'));

  const getCountryByLanguage = () => {
    const language = localStorage.getItem('i18nextLng');
    const languageKey = language.split('-')[0].toUpperCase(); // To obtain "FR" with "fr-FR"

    // if language "en-GB", send "GB" to the flag component
    if (languageKey === 'EN' && language === 'en-GB') {
      return { alpha2: 'GB' };
    }
    const country = countries.find((country) => country.alpha2 === languageKey);

    return country;
  };

  const handleLogin = async() => {
    await AuthService.doLogin();
  };

  return (
    <Layout style={{ height: '100vh' }}>
      <Header style={{ backgroundColor: 'white', padding: '20px 50px', height: 104 }}>
        <Flex justify='space-between' align='center' style={{ height: '100%' }}>
          <img
            src={windowSize.width > sizes.sm ? process.env.PUBLIC_URL + '/assets/logo-shopopop.svg' : process.env.PUBLIC_URL + '/assets/short-logo.svg'}
            alt="ShopopopLogo"
          />
          <Flex align='center' gap={10} style={{ width: '160px' }} >
            {windowSize.width > sizes.sm ?
              <div style={{
                width: '40px',
                borderRadius: '4px',
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
                <ReactCountryFlag
                  countryCode={getCountryByLanguage()?.alpha2}
                  svg
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                />
              </div> :
              null}
            <LanguageSelector inputStyle={{ width: '100%' }} />
          </Flex>
        </Flex>
      </Header>
      <Content >
        <Row style={{ height: 'calc(100vh - 104px)' }}>
          <Col
            xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 11 }}
            css={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'start',
              padding: '100px 0px 0px 50px',
              width: '100%',
              backgroundColor: 'white',
              [breakpoints.md]: {
                'justifyContent': 'center',
                'padding': '0px 0px 0px 50px',
                '& > *': {
                  transform: 'translateY(-100px)',
                },
              },
            }}
          >
            <Flex vertical gap='large'>
              <Title style={{ marginBottom: 0, fontWeight: 'bold' }}>{t('LOGIN_HELLO')}</Title>
              <Text>{t('LOGIN_GREETING')}</Text>
              <button className="gsi-material-button" onClick={handleLogin}>
                <div className="gsi-material-button-state"></div>
                <div className="gsi-material-button-content-wrapper">
                  <div className="gsi-material-button-icon">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" style={{ display: 'block' }}>
                      <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                      <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                      <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                      <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                      <path fill="none" d="M0 0h48v48H0z"></path>
                    </svg>
                  </div>
                  <span className="gsi-material-button-contents">{t('LOGIN_GOOGLE')}</span>
                  <span style={{ display: 'none' }}>{t('LOGIN_GOOGLE')}</span>
                </div>
              </button>
            </Flex>
          </Col>
          <Col
            xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 0 }} lg={{ span: 13 }}
            style={{ backgroundColor: '#FDFAF3' }}
          >
            <Space style={{ width: '100%', height: 'calc(100vh - 104px)', justifyContent: 'center' }} direction='vertical' align='center'>
              <img src={process.env.PUBLIC_URL + '/assets/pc-bo-login.svg'} alt="ShopopopBoPicto" />
            </Space>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}

export default Login;
