import { ReactNode, useState, useEffect } from 'react';
import { Card, AutoComplete, Radio, InputNumber, Row, Col, Input, theme, Tooltip, Form } from 'antd';
import { FormInstance } from 'antd/es/form';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import HighlightText from './HighlightText';
import { AddressOption, RecipientAddressInformationProps } from '../interfaces/RecipientAddressInformationProps';
import { useTranslation } from 'react-i18next';
import useDebounce from '../../../hooks/src/hooks/useDebounce';
import { NO_EMOJI_REGEX } from '@shopopop/backoffice-frontend-utils';

interface RecipientDeliveryAddressProps extends RecipientAddressInformationProps {
  form: FormInstance;
  onAddressSearch: (query: string) => Promise<AddressOption[]>;
  onAddressSelect?: ({ address, placeId }: AddressOption) => void;
  onAddressClear?: () => void;
}

const { TextArea } = Input;

function RecipientDeliveryAddress(props: RecipientDeliveryAddressProps): ReactNode {
  const {
    form,
    original_address: originalAddress,
    elevator,
    floor,
    additional_info: additionalInfo,
    onAddressSearch,
    onAddressSelect,
    onAddressClear,
  } = props;

  const { t } = useTranslation();
  const { token } = theme.useToken();

  const [options, setOptions] = useState<AddressOption[]>([]);
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(!!originalAddress);
  const [searchTerm, setSearchTerm] = useState('');
  const [previousSelectedAddress, setPreviousSelectedAddress] = useState(originalAddress);

  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  useEffect(() => {
    if (debouncedSearchTerm.length >= 3 && debouncedSearchTerm.length < 100) {
      onAddressSearch(debouncedSearchTerm).then((suggestions: AddressOption[]) => {
        setOptions(suggestions.slice(0, 5));
      });
    } else if (debouncedSearchTerm.length === 0) {
      setOptions([]);
    }
  }, [debouncedSearchTerm, onAddressSearch, originalAddress]);

  useEffect(() => {
    form.setFieldsValue({
      address: originalAddress,
      elevator: elevator,
      floor: floor,
      comment: additionalInfo,
    });
  }, [form, originalAddress, elevator, floor, additionalInfo]);

  const handleSelect = (value: string) => {
    const { address, placeId } = JSON.parse(value);
    form.setFieldsValue({ address });
    setSearchTerm('');
    setOptions([]);
    setShowAdditionalInfo(true);
    if (previousSelectedAddress !== value) {
      setPreviousSelectedAddress(value);
      onAddressSelect?.({ address, placeId });
      form.setFieldsValue({ elevator: 'dontknow', floor: undefined, comment: '' });
    }
  };

  return (
    <Card title={t('RCP_ADDRESS_TITLE')}>
      <Row gutter={16}>
        <Col xs={24}>
          <Form.Item
            name='address'
            label={t('RCP_ADDRESS')}
            rules={[
              { required: true, message: t('RCP_ADDRESS_REQUIRED') },
              {
                pattern: NO_EMOJI_REGEX,
                message: t('INVALID_VALUE'),
              },
              () => ({
                validator() {
                  if (searchTerm.length >= 3) {
                    return Promise.reject(new Error(t('ERROR_OCCURED_ON_ADDRESS_CREATION')));
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
          >
            <AutoComplete
              data-testid='address-search'
              options={options.map((option) => ({
                value: JSON.stringify({ address: option.address, placeId: option.placeId }),
                label: (
                  <div>
                    <HighlightText
                      text={option.address}
                      highlightTag={searchTerm}
                    />
                  </div>
                ),
              }))}
              onSearch={(value) => {
                setSearchTerm(value);
              }}
              onSelect={handleSelect}
              onClear={() => onAddressClear?.()}
            >
              <Input />
            </AutoComplete>
          </Form.Item>
        </Col>
        {showAdditionalInfo && (
          <>
            <Col xs={24} xl={12}>
              <Form.Item
                name='elevator'
                label={t('LIFT')}
                rules={[
                  { required: true, message: t('LIFT_REQUIRED') },
                ]}
              >
                <Radio.Group
                  data-testid='elevator-radio'
                  style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
                >
                  <Radio.Button value={'yes'} style={{ flex: 1, textAlign: 'center' }}>{t('LIFT_YES')}</Radio.Button>
                  <Radio.Button value={'no'} style={{ flex: 1, textAlign: 'center' }}>{t('LIFT_NO')}</Radio.Button>
                  <Radio.Button value={'dontknow'} style={{ flex: 1, textAlign: 'center', whiteSpace: 'nowrap' }}>{t('LIFT_UNKNOWN')}</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
              <Form.Item
                name='floor'
                label={t('FLOOR')}
                rules={[
                  {
                    pattern: /^(?=.{0,40}$).*/g,
                    message: t('FLOOR_LIMIT_LENGHT'),
                  },
                  {
                    type: 'number',
                    min: 0,
                    max: 40,
                    message: t('FLOOR_LIMIT_LENGHT'),
                  },
                  {
                    pattern: /^[^£¥€$©®¿?¡!`,;.:/÷+=%)(“§&#@<>_°*}\]{[«»≠•—≤]*$/g,
                    message: t('INVALID_VALUE'),
                  },
                  {
                    pattern: NO_EMOJI_REGEX,
                    message: t('INVALID_VALUE'),
                  },
                ]}
              >
                <InputNumber
                  data-testid='floor-input'
                  min={0}
                  max={40}
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name='comment'
                label={
                  <span>
                    {t('ADDRESS_ADDITIONAL_INFO')}
                    <Tooltip title={t('TOOLTIP_ADDITIONAL_INFO_ADDRESS')}>
                      <QuestionCircleTwoTone twoToneColor={token.colorPrimary} style={{ marginLeft: 4 }} />
                    </Tooltip>
                  </span>
                }
                rules={[
                  {
                    pattern: NO_EMOJI_REGEX,
                    message: t('INVALID_VALUE'),
                  },
                ]}
              >
                <TextArea
                  showCount
                  maxLength={255}
                  data-testid='comment-input'
                  style={{ height: 52 }}
                />
              </Form.Item>
            </Col>
          </>
        )}
      </Row>
    </Card>
  );
}

export default RecipientDeliveryAddress;
