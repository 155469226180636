/** @jsxImportSource @emotion/react */
import { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useRouter } from '../../../hooks/useRouter';
import { useFetch } from 'use-http';
import { useTranslation } from 'react-i18next';
import { Tabs, Form, Row, Col } from 'antd';
import Save from './Save';
import Delivery from './Delivery/Delivery';
import Recipient from './Delivery/Recipient';
import UpdateOrder from './Delivery/UpdateOrder';
import UpdateShopper from './Delivery/UpdateShopper';
import UpdateDelivery from './UpdateDelivery';
import History from './History/History';
import TouchableView from '../../DeviceDetect/TouchableView';
import UnTouchableView from '../../DeviceDetect/UntouchableView';
import Archivage from '../../Archivage/Archivage';
import StatusErrorWrapper from '../../Wrapper/StatusErrorWrapper';
import BreadcrumbContext from '../../../context/BreadcrumbContext';
import { NotificationCenterContext } from '../../../context/NotificationCenterContext';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { getShiftedCountsDateAndSlot } from '../../../utils/getShiftedCountsDateAndSlot';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import DeliveryIncidentDrawer from '../../DeliveryIncident/DeliveryIncidentDrawer';
import EditDelivery from './EditDelivery/EditDelivery';
import { readIncident } from '../../../services/apiEndPoints/delivery/incident';
import { readHistory } from '../../../services/apiEndPoints/delivery/detail/readHistory';
import AddressesListCard from '../../Addresses/AddressesListCard';
import { readUserAddresses } from '../../../services/apiEndPoints/addresses/userAddress/readUserAddresses';
import reorderObjectToFirst from '../../../utils/manipulateArray/reorderObjectToFirst';

const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

/**
 * Delivery Detail
 * @return {String}
 */
const Detail = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const saveBarRef = useRef();
  const deliveryId = router.query.id;

  const updateDeliveryFetch = useFetch(process.env.REACT_APP_API_BO, {
    cachePolicy: 'no-cache',
  });
  const getDeliveryFetch = useFetch(process.env.REACT_APP_API_BO, {
    cachePolicy: 'no-cache',
  });
  const getPushesData = useFetch(process.env.REACT_APP_API_BO, {
    cachePolicy: 'no-cache',
  });
  const pushFetch = useFetch(process.env.REACT_APP_API_BO, {
    cachePolicy: 'no-cache',
  });
  // Context
  const notification = useContext(NotificationCenterContext);
  const contextBreadcrumb = useContext(BreadcrumbContext);
  // State
  const [delivery, setDelivery] = useState({});
  const [deliveryAddress, setDeliveryAddress] = useState();
  const [selectedDriveId, setSelectedDriveId] = useState();
  const [statusError, setStatusError] = useState(200);
  const [searchUser, setSearchUser] = useState(null);
  const [payload, setPayload] = useState(null);
  const [staticDelivery, setStaticDelivery] = useState(true);
  const [activeTab, setActiveTab] = useState(router.query.tab);
  const [historyDelivery, setHistoryDelivery] = useState([]);
  const [pushesDelivery, setPushesDelivery] = useState([]);
  const [deliveryShifted, setDeliveryShifted] = useState(0); // Remove when this old feature is removed (BetaFeature)
  const [deliveryShiftedCount, setDeliveryShiftedCount] = useState({});
  const [deliveryShopperCancelled, setDeliveryShopperCancelled] = useState(0);
  const [pushAtUpdate, setPushAtUpdate] = useState(false);
  const [historyLoading, setHistoryLoading] = useState(false);
  // Incident states
  const [incident, setIncident] = useState(null);
  const [incidentsHistory, setIncidentsHistory] = useState([]);
  // Address states
  const [addressErrorsInfos, setAddressErrorsInfos] = useState({});
  const [addressesLoading, setAddressesLoading] = useState(true);
  const [addresses, setAddresses] = useState([]);
  const initialAddresses = useRef();

  useDocumentTitle(delivery ? `${t('URL_DELIVERY_DETAIL')} ${delivery.recipient?.first_name} ${delivery.recipient?.last_name}` : t('URL_DELIVERY_DETAIL'));

  const getDeliveriesDetails = async() => {
    await getDeliveryFetch.get(`/deliveries/details/${deliveryId}`);
    if (getDeliveryFetch.response.status === 200) {
      setDelivery(getDeliveryFetch.response.data);
      !getDeliveryFetch.response.data.validated || getDeliveryFetch.response.data.status === 6 || getDeliveryFetch.response.data.status === 8 ? setStaticDelivery(false) : setStaticDelivery(true);
      setSelectedDriveId(getDeliveryFetch.response.data.drive_id);
      contextBreadcrumb.setInfoBreadcrumb(getDeliveryFetch.response.data);
      setDeliveryAddress(getDeliveryFetch.response.data.recipient.address);
    } else {
      setStatusError(getDeliveryFetch.response.status);
    }
  };

  /**
   * getHistory - Get the history of the delivery
   * @param {number} deliveryId - The delivery id
   * @return {Promise<void>}
   */
  const getHistory = async() => {
    setHistoryLoading(true);
    readHistory(deliveryId)
      .then((response) => {
        if (response.type === 'success') {
          setHistoryLoading(false);
          setHistoryDelivery(response.data.filter((historyDelivery) => historyDelivery.type !== 'INCIDENT' && historyDelivery.type !== 'INCIDENT_MINOR' && historyDelivery.type !== 'INCIDENT_MAJOR'));
          setDeliveryShifted(response.data.filter((historyDelivery) => historyDelivery.type === 'SHIFTED').length); // Remove when this old feature is removed (BetaFeature)
          setDeliveryShopperCancelled(response.data.filter((historyDelivery) => historyDelivery.type === 'CANCELED').length);
        }
      });
  };

  useEffect(() => {
    setDeliveryShiftedCount(getShiftedCountsDateAndSlot(historyDelivery.filter((historyDelivery) => historyDelivery.type === 'SHIFTED')));
  }, [historyDelivery]);

  /**
   * getPushes - Get the pushes of the delivery
   * @param {number} deliveryId - The delivery id
   * @return {Promise<void>}
   */
  const getPushes = async() => {
    await getPushesData.get(`/deliveries/${deliveryId}/pushes`);
    if (getPushesData.response.status === 200) {
      setPushesDelivery(getPushesData.response.data);
    } else {
      console.error(getPushesData.response.status);
    }
  };

  const readIncidentsHistory = () => {
    readIncident(deliveryId)
      .then((response) => {
        if (response.type === 'success') {
          const historyDataBuffer = response.data.flatMap((incidentObject) => {
            if (incidentObject.incidentClosed) {
              const objectIncident = {
                callerFirstName: incidentObject.callerFirstName,
                callerId: incidentObject.callerId,
                callerLastName: incidentObject.callerLastName,
                comment: incidentObject.comment,
                createdAt: incidentObject.createdAt,
                deliveryId: incidentObject.deliveryId,
                deliveryClosedRequested: incidentObject.deliveryClosedRequested,
                incidentReasons: incidentObject.incidentReasons,
                incidentTypes: incidentObject.incidentTypes,
                kilometerRange: incidentObject.kilometerRange,
                type: 'DELIVERY_INCIDENT' };
              const objectCanceled = {
                callerFirstName: incidentObject.updateByFirstName,
                callerId: incidentObject.updatedBy,
                callerLastName: incidentObject.updateByLastName,
                createdAt: incidentObject.updatedAt,
                tips: incidentObject.tips,
                type: 'INCIDENT_CANCELED',
              };
              return [objectIncident, objectCanceled];
            } else {
              return [{ ...incidentObject, type: 'DELIVERY_INCIDENT' }];
            }
          });
          setIncidentsHistory(historyDataBuffer);
          const haveOpenIncident = response.data.filter((incident) => !incident.incidentClosed);
          haveOpenIncident.length === 0 ? setIncident(null) : setIncident(haveOpenIncident[0]);
          getDeliveriesDetails();
        }
      });
  };


  useEffect(() => {
    getDeliveriesDetails();
    getHistory();
    getPushes();
    readIncidentsHistory();
  }, []);

  // récupérer les adresses du destinataire de la livraison une fois ce dernier chargé
  useEffect(() => {
    if (delivery.recipient && staticDelivery) {
      readUserAddresses({ userId: delivery.recipient.id })
        .then((response) => {
          setAddressesLoading(false);
          const reorderAddressesList = reorderObjectToFirst({
            array: response.data,
            key: 'id',
            value: delivery.address_id ?? delivery.recipient.address.id,
            object: deliveryAddress ?? delivery.recipient.address,
          });
          setAddresses(reorderAddressesList);
          initialAddresses.current = reorderAddressesList;
        });
    }
    if (delivery.recipient && !staticDelivery) {
      setAddressesLoading(false);
      setAddresses([delivery.recipient.address]);
    }
  }, [delivery]);

  // Utilisation d'une callback pour récupérer l'adresse sélectionnée et afficher ou non la barre de sauvegarde
  const onChangeSelectedAddress = useCallback((address) => {
    setDeliveryAddress(address);
  });

  // Mettre à jour l'adresse de livraison lorsqu'on crée une nouvelle adresse ou qu'on en modifie une
  useEffect(() => {
    if (initialAddresses.current && (initialAddresses.current !== addresses)) {
      setDeliveryAddress(addresses[0]);
    }
  }, [addresses]);

  // Mettre à jour la barre de sauvegarde lorsqu'on modifie l'adresse de livraison
  useEffect(() => {
    if (delivery.recipient && (deliveryAddress.id !== delivery.recipient.address.id || addressErrorsInfos.message)) {
      saveBarRef.current.showBar();
    } else {
      saveBarRef.current.hideBar();
    }
  }, [deliveryAddress]);


  // Permet de réinitialiser la liste des adresses à l'annulation de la modification de la livraison
  const resetAddressesList = () => {
    setAddresses([...addresses]);
  };

  // on utilise un useEffect pour mettre à jour l'historique uniquement lorsqu'il est affiché et si la livraison est modifiée
  useEffect(() => {
    if (activeTab === 'history') {
      getHistory();
    }
  }, [delivery, activeTab]);

  // on utilise un useEffect pour mettre à jour le payload de delivery_end quand delivery_start est modifié, il y avait un décalage dans le onfinish
  useEffect(() => {
    if (payload?.delivery_start) {
      setPayload({ ...payload,
        delivery_end: dayjs(`${dayjs(form.getFieldValue('deliveryDatePickerDay')).format('YYYY-MM-DD')} ${form.getFieldValue('deliveryEnd')}`),
      });
    }
  }, [payload?.delivery_start]);

  // on effectue un swich sur le nom de la clé du form antd pour mettre à jour le payload
  const updatePayload = (info) => {
    saveBarRef.current.showBar();
    switch (Object.keys(info)[0]) {
    case 'tips':
      setPayload({
        ...payload, tips: info.tips,
      });
      break;
    case 'sizeCommand':
      setPayload({
        ...payload, transport_size: info.sizeCommand,
      });
      break;
    case 'reference':
      setPayload({
        ...payload, reference: info.reference,
      });
      break;
    case 'storeComment':
      setPayload({
        ...payload, additional_info: info.storeComment,
      });
      break;
    case 'orderAmount':
      setPayload({
        ...payload, ord_value: info.orderAmount,
      });
      break;
    case 'articlesAmount':
      setPayload({
        ...payload, nbr_article: info.articlesAmount,
      });
      break;
    default:
      break;
    }
  };

  const sendPush = async() => {
    await pushFetch.post('/deliveries/notifications', [deliveryId]);
    if (pushFetch.response) {
      saveBarRef.current.hideBar();
      setPushAtUpdate(!pushAtUpdate);
      notification.push('success', t('PUSH_SUCCES_ONE'));
    }
  };

  const updateDelivery = async(e) => {
    let finalPayload = payload;
    setAddressErrorsInfos({});

    if (deliveryAddress?.id !== undefined && deliveryAddress?.id !== delivery.recipient.address.id) {
      finalPayload = {
        ...payload,
        address_id: deliveryAddress.id,
      };
    } else {
      finalPayload = {
        ...finalPayload,
        address_id: delivery.recipient.address.id,
      };
    }

    if (pushAtUpdate) {
      sendPush();
    }

    if (e.hourStart && e.hourEnd) {
      const newDeliveryStart = dayjs(e.hourStart).second(0);
      const newDeliveryEnd = dayjs(e.hourEnd).second(0);
      if (!newDeliveryStart.isSame(dayjs(delivery.delivery_start), 'minute')) {
        finalPayload = {
          ...finalPayload,
          delivery_start: newDeliveryStart.toISOString(),
          delivery_end: newDeliveryEnd.toISOString(),
        };
      }
      if (!newDeliveryEnd.isSame(dayjs(delivery.delivery_end), 'minute')) {
        finalPayload = {
          ...finalPayload,
          delivery_end: newDeliveryEnd.toISOString(),
        };
      }
    }

    await updateDeliveryFetch.put(`/deliveries/${deliveryId}`, finalPayload);
    const responseData = updateDeliveryFetch.response.data;
    if (updateDeliveryFetch.response.ok) {
      setAddressErrorsInfos({});
      notification.push('success', t('DELIVERY_CHANGED_SUCCESSFULLY'));
      if (!dayjs(responseData.delivery_start).isSame(dayjs(delivery.delivery_start), 'minute') || !dayjs(responseData.delivery_end).isSame(dayjs(delivery.delivery_end), 'minute')) {
        setDeliveryShifted(deliveryShifted + 1); // Remove when this old feature is removed (BetaFeature)
        setHistoryDelivery([
          ...historyDelivery,
          {
            additional_info:
            { old_end_date: delivery.delivery_end,
              old_start_date: delivery.delivery_start,
              new_end_date: responseData.delivery_end,
              new_start_date: responseData.delivery_start,
            },
            date: dayjs().utc().format(),
            type: 'SHIFTED',
          },
        ]);
      }
      saveBarRef.current.hideBar();
      setPayload(null);
      setDelivery({
        ...delivery,
        tips: responseData.tips,
        delivery_start: responseData.delivery_start,
        delivery_end: responseData.delivery_end,
        withdrawal_start: responseData.withdrawal_start,
        withdrawal_end: responseData.withdrawal_end,
        distance: responseData.distance_drive_dest,
        drive_id: responseData.drive_id,
        address_id: responseData.address_id,
        details: {
          ...delivery.details,
          transport_size: e.sizeCommand, // Donnée non présente dans le retour de l'API, on se fie à la valeur côté front.
          reference: responseData.reference,
          additional_info: responseData.additional_info,
          ord_value: responseData.ord_value,
          nbr_article: responseData.nbr_article,
        },
      });
    } else {
      if (updateDeliveryFetch.response.status === 400) {
        if (responseData.errors[0].message === 'DELIVERY_DATE_TOO_CLOSE') {
          return notification.push('error', t('DELIVERY_SLOT_EXPIRED'));
        }

        // Passer en rouge l'adresse qui a généré une erreur
        if (responseData.errors[0].message === 'DELIVERY_DISTANCE_TOO_LONG') {
          setAddressErrorsInfos({ ...addressErrorsInfos, address_id: finalPayload.address_id, message: 'ADDRESS_OUT_OF_RANGE' });
          return notification.push('error', t('MODIF_NOT_SAVED'));
        }
        // Mettre à jour la liste des adresses et le selecteur dans le cas où après avoir choisit une adresse trop éloignée
        // on souhaite revenir à l'adresse initiale
        if (responseData.errors[0].message === 'NO_DATA' && addressErrorsInfos.message) {
          const resetAddressesList = reorderObjectToFirst({ array: addresses, key: 'id', value: deliveryAddress.id });
          setAddresses([...resetAddressesList]);
          setDeliveryAddress({ ...resetAddressesList[0] });
        }
      }
      if (updateDeliveryFetch.response.status === 404) {
        notification.push('error', t('DELIVERY_NOT_FOUND'));
      }
      if (updateDeliveryFetch.response.status === 409) {
        notification.push('error', t('DELIVERY_ALREADY_EXISTS'));
      }
      if (updateDeliveryFetch.response.status === 503) {
        notification.push('error', t('EXTERNAL_SERVICE_ERROR'));
      }
    }
  };

  const onClickTab = (key) => {
    setActiveTab(key);
    navigate(`?tab=${key}`);
  };

  const tabsItems = [
    {
      label: t('DELIVERY_STEP'),
      key: 'delivery',
      children: (
        <Row gutter={[20, 20]}>
          {staticDelivery ?
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Delivery
                delivery={delivery}
                push={pushesDelivery?.length}
                deliveryShifted={deliveryShifted}
                deliveryShiftedDateCount={deliveryShiftedCount.shiftedDateCount}
                deliveryShiftedSlotCount={deliveryShiftedCount.shiftedSlotCount}
                deliveryShopperCancelled={deliveryShopperCancelled}
                loading={getDeliveryFetch.loading}
              />
            </Col> : <></>
          }
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Recipient delivery={delivery} />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <AddressesListCard
              addresses={addresses}
              setAddresses={setAddresses}
              title='ADDRESSES'
              maxRows={2}
              actions={{
                update: staticDelivery,
                create: staticDelivery,
                select: staticDelivery,
              }}
              addressesLoading={addressesLoading}
              userId={delivery?.recipient?.id}
              selectOtherAddress={onChangeSelectedAddress}
              addressErrorsInfos={addressErrorsInfos}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <UpdateOrder
              form={form}
              delivery={delivery}
              setSelectedDriveId={setSelectedDriveId}
              selectedDriveId={selectedDriveId}
              staticDelivery={staticDelivery}
              payload={payload}
              setPayload={setPayload}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            {(delivery.validated || (!delivery.validated && delivery.shopper)) &&
              <UpdateShopper delivery={delivery} setDelivery={setDelivery} searchUser={searchUser} setSearchUser={setSearchUser} staticDelivery={staticDelivery}/>
            }
          </Col>
        </Row>
      ),
    },
    {
      label: t('HISTORY_DELIVERYDETAIL'),
      key: 'history',
      children: (
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <History
              delivery={delivery}
              history={historyDelivery}
              pushes={pushesDelivery}
              incident={incidentsHistory}
              loading={
                updateDeliveryFetch.loading ||
                getDeliveryFetch.loading ||
                historyLoading ||
                getPushesData.loading
              }
            />
          </Col>
        </Row>
      ),
    },
  ];

  const DeliveryActions = () => {
    return (
      delivery.validated ?
        <div css={{ display: 'flex', gap: 10 }}>
          {delivery.status === 6 && <EditDelivery delivery={delivery} setDelivery={setDelivery}/>}
          {
            delivery.status >= 3 &&
            delivery.status !== 8 &&
            <DeliveryIncidentDrawer
              incident={incident}
              setIncident={setIncident}
              setDelivery={setDelivery}
              driveName={delivery.details.drive_name}
              updateIncidentsHistory={readIncidentsHistory}
              deliveryStatus={delivery.status}
            />
          }
          <Archivage
            delivery={delivery}
            setDelivery={setDelivery}
            setStaticDelivery={setStaticDelivery}
          />
        </div> : <></>
    );
  };

  return (
    <StatusErrorWrapper statusError={statusError}>
      <Form
        form={form}
        layout="vertical"
        onValuesChange={(e) => updatePayload(e)}
        onFinish={(e) => updateDelivery(e)}
      >
        <TouchableView>
          <DeliveryActions />
          <UpdateDelivery
            form={form}
            touch={true}
            delivery={delivery}
            loading={getDeliveryFetch.loading}
            deliveryShopperCancelled={deliveryShopperCancelled}
            setDelivery={setDelivery}
            setSearchUser={setSearchUser}
            setStaticDelivery={setStaticDelivery}
            setPushAtUpdate={setPushAtUpdate}
            setDeliveryShopperCancelled={setDeliveryShopperCancelled}
            pushAtUpdate={pushAtUpdate}
            push={pushesDelivery?.length}
            incident={incident}
          />
        </TouchableView>

        <div>
          <div className='dual-panel-wrapper'>
            <div>
              <UnTouchableView>
                <UpdateDelivery
                  form={form}
                  delivery={delivery}
                  loading={getDeliveryFetch.loading}
                  deliveryShopperCancelled={deliveryShopperCancelled}
                  setDelivery={setDelivery}
                  setSearchUser={setSearchUser}
                  setStaticDelivery={setStaticDelivery}
                  setPushAtUpdate={setPushAtUpdate}
                  setDeliveryShopperCancelled={setDeliveryShopperCancelled}
                  pushAtUpdate={pushAtUpdate}
                  push={pushesDelivery?.length}
                  incident={incident}
                />
              </UnTouchableView>
            </div>
            <div>
              <Tabs
                items={tabsItems}
                animated={true}
                defaultActiveKey={activeTab}
                onChange={onClickTab}
                tabBarExtraContent={
                  <UnTouchableView>
                    <DeliveryActions />
                  </UnTouchableView>
                }
              />
            </div>
          </div>

          <Save
            ref={saveBarRef}
            form={form}
            isLoading={updateDeliveryFetch.loading}
            setDeliveryAddress={setDeliveryAddress}
            delivery={delivery}
            setSelectedDriveId={setSelectedDriveId}
            setAddressErrorsInfos={setAddressErrorsInfos}
            setPushAtUpdate={setPushAtUpdate}
            resetAddressesList={resetAddressesList}
          />
        </div>
      </Form>
    </StatusErrorWrapper>
  );
};

export default Detail;
