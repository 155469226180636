import { ReactNode } from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

function SearchResultsCount({ count }: { count: number }): ReactNode {
  return <Text>{count} {count > 1 ? 'résultats de recherche' : 'résultat de recherche'}</Text>;
}

export default SearchResultsCount;
