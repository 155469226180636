import { Card, Form, Row, Col, Input, Typography, Button, Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import InputPhoneNumber from '../../Input/InputPhoneNumber/InputPhoneNumber';
import { FormInstance } from 'antd/lib/form';
import { ReactNode, useState, KeyboardEvent } from 'react';
import { CountryCode } from 'libphonenumber-js';
import { NO_EMOJI_REGEX } from '@shopopop/backoffice-frontend-utils';
import InputEmail from '../../Input/InputEmail.tsx';
import { useTrimmedFields } from '@shopopop/react-hooks';

interface Values {
  name?: string;
  email?: string;
  telephone?: string;
}

const { Text } = Typography;

interface FormUserSearchProps {
  country: string;
  onSearchUser: (values: Values) => void;
  form: FormInstance;
  userSearchLoading: boolean;
}
function FormUserSearch({ country, onSearchUser, form, userSearchLoading }: Readonly<FormUserSearchProps>): ReactNode {
  const { t } = useTranslation();
  const [formInError, setFormInError] = useState(false);

  // Trim the email input value
  useTrimmedFields({
    watchedInput: {
      value: Form.useWatch('email', form),
      name: 'email',
    },
    setFormValue: form.setFieldsValue,
  });

  const handleSubmit = () => {
    form.validateFields().then((values: Values) => {
      const filteredValues = Object.fromEntries(
        Object.entries(values).filter(([, value]) => value !== null && value !== undefined && value !== ''),
      );

      if (Object.values(filteredValues).length > 0) {
        onSearchUser(filteredValues);
        setFormInError(false);
      } else {
        setFormInError(true);
      }
    });
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <Flex gap={20} vertical>
      <Card title={t('SEARCH_EXISTING_RCP_TITLE')} onKeyDown={handleKeyDown}>
        <Flex gap={16} vertical>
          <Text
            italic
            type={formInError ? 'danger' : 'secondary'}
            data-testid="textExplain-FormUserSearch"
          >
            {t('SEARCH_EXISTING_RCP_DESC')}
          </Text>
          <Row gutter={20}>
            <Col xs={24} md={24}>
              <Form.Item
                name='name'
                label={t('RCP_SEARCH_FIRSTNAME_LASTNAME')}
                rules={[{
                  pattern: /^[^\d]*$/g,
                  message: t('NUMBER_NOT_ALLOWED'),
                }, {
                  pattern: /^(?=.{1,100}$).*/g,
                  message: t('FULL_NAME_LIMITE_LENGTH'),
                }, {
                  pattern: /^[^£¥€$©®¿?¡!`,;.:/÷+=%)(“§&#@<>_°*}\]{[«»≠•—≤]*$/g,
                  message: t('INVALID_VALUE'),
                },
                {
                  pattern: NO_EMOJI_REGEX,
                  message: t('INVALID_VALUE'),
                },
                ]}
              >
                <Input data-testid="inputName-FormUserSearch" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <InputEmail
                name={'email'}
                label={t('RCP_SEARCH_EMAIL')}
                dataTestid={'inputEmail-FormUserSearch'}
              />
            </Col>
            <Col xs={24} md={12}>
              <InputPhoneNumber
                form={form}
                country={country as CountryCode}
              />
            </Col>
          </Row>
        </Flex>
      </Card>
      <Flex justify='center'>
        <Button type='primary' onClick={() => handleSubmit()} loading={userSearchLoading}>{t('RCP_SEARCH_BUTTON')}</Button>
      </Flex>
    </Flex>
  );
}

export default FormUserSearch;
