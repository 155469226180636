import React, { createContext } from 'react';
import { theme, notification } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

/**
 * TODO: Refactoring du code de façon général concernant les notifications.
 * Exemples:
 * notification.success(config)
 * notification.error(config)
 * notification.info(config)
 * notification.warning(config)
 * notification.open(config)
 * notification.destroy(key?: String)
 *
 * Voir doc d'Ant Design à ce sujet: https://ant.design/components/notification#design-token
 */

/**
 * @type {React.Context<NotificationCenter>}
 */
export const NotificationCenterContext = createContext();

/**
 * NotificationCenterContextWrapper - A wrapper for providing notification center context.
 * @param {JSX.Element} children
 * @return {JSX.Element}
 */
export function NotificationCenterContextWrapper({ children }) {
  const { token } = theme.useToken();
  const checkType = (type) => {
    switch (type) {
    case 'success':
      return <CheckCircleOutlined style={{ color: token.colorSuccess }} />;
    case 'error':
      return <CloseCircleOutlined style={{ color: token.colorError }} />;
    default:
      return;
    }
  };

  /**
   * Close notification.
   * @function closeNotification
   * @return {void}
   */
  function closeNotification() {
    notification.destroy();
  }
  /**
   * Push a notification into notification Center.
   * @function push
   * @param {"error" | "warning" | "info" | "success"} type - Notification type.
   * @param {string} title - Translated notification title.
   * @param {string} description - Translated notification description.
   */
  function push(type, title, description) {
    notification[type]({
      message: title,
      description: description,
      icon: checkType(type),
      closeIcon: <img src={process.env.PUBLIC_URL + '/assets/path.svg'} alt="path" style={{ width: 12, height: 12 }} />,
      className: 'force-antd-notification-style',
    });
  }

  /**
   * @namespace NotificationCenter
   */
  const contextObject = {
    push,
    closeNotification,
  };

  return (
    <NotificationCenterContext.Provider value={contextObject} >
      {children}
    </NotificationCenterContext.Provider>
  );
}
