import frFR from 'antd/lib/locale/fr_FR';
import enUS from 'antd/lib/locale/en_US';
import esES from 'antd/lib/locale/es_ES';
import nlNL from 'antd/lib/locale/nl_NL';
import itIT from 'antd/lib/locale/it_IT';

interface Locale {
    file: any;
    keys: string[];
    name: string;
}

const mapI18N: Locale[] = [{
  file: frFR,
  keys: ['fr-FR', 'fr'],
  name: 'Français',
}, {
  file: enUS,
  keys: ['en-GB', 'en', 'en-US'],
  name: 'English',
}, {
  file: esES,
  keys: ['es', 'es-ES'],
  name: 'Español',
}, {
  file: nlNL,
  keys: ['nl', 'nl-NL'],
  name: 'Dutch',
}, {
  file: itIT,
  keys: ['it', 'it-IT'],
  name: 'Italiano',
}];

export default mapI18N;
