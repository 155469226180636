
/**
 * Reorder an object to the first position of an array
 * @param {Array} array - The array to reorder
 * @param {String} key - The key to reorder
 * @param {Number} value - The value to reorder
 * @return {Array} - The reordered array
 */
function reorderObjectToFirst({ array, key, value, object }) {
  const index = array.findIndex((item) => item[key] === value);
  if (index > 0) {
    const item = array.splice(index, 1);
    array.unshift(item[0]);
  }
  if (index === -1) {
    array.unshift(object);
  }
  return array;
}

export default reorderObjectToFirst;
