import dayjs, { Dayjs } from 'dayjs';
import { roundToNextQuarterHour } from '..';

import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween);

interface InitialTimesProps {
  now: Dayjs;
}

interface InitialTimes {
  start: Dayjs;
  end: Dayjs;
}

const MORNING_START_HOUR = 10;
const AFTERNOON_END_HOUR = 18;
const DEFAULT_DURATION_HOURS = 2;

function calculateInitialTimes({ now }: InitialTimesProps): InitialTimes {
  let start: Dayjs;
  let end: Dayjs;
  const currentHour = now.hour();

  if (currentHour >= 0 && currentHour < 8) {
    // Cas entre minuit et 8h du matin
    start = now.hour(MORNING_START_HOUR).minute(0);
    end = start.add(DEFAULT_DURATION_HOURS, 'hour');
  } else if (currentHour >= 8 && currentHour < AFTERNOON_END_HOUR) {
    // Cas entre 8h et 18h
    start = roundToNextQuarterHour(now).add(DEFAULT_DURATION_HOURS, 'hour');
    end = start.add(DEFAULT_DURATION_HOURS, 'hour');

    // Cas entre 17h45 et 18h
    if (now.isBetween(dayjs(now).hour(17).minute(45), dayjs(now).hour(18).minute(0))) {
      end = end.hour(21).minute(0);
    }

  } else if (currentHour >= AFTERNOON_END_HOUR && currentHour < 24) {
    // Cas entre 20h et minuit
    start = dayjs(now).add(1, 'day').hour(MORNING_START_HOUR).minute(0);
    end = start.add(DEFAULT_DURATION_HOURS, 'hour');
  } else {
    start = dayjs(now).hour(MORNING_START_HOUR).minute(0);
    end = start.add(DEFAULT_DURATION_HOURS, 'hour');
  }

  return {
    start: roundToNextQuarterHour(start),
    end: roundToNextQuarterHour(end),
  };
}

export default calculateInitialTimes;
