import React from 'react';
import { Input, InputNumber, Checkbox, Form, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import useCustomHtml from '../../hooks/useCustomHtml';
import { INPUT_VALIDATION_REGEX, NO_EMOJI_REGEX } from '../../utils/regex';

/**
 * AddressForm
 * @param {Object} defaultAddress
 * @return {string}
 */
function AddressForm({ defaultAddress }) {
  const { TextArea } = Input;
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <p style={{ fontStyle: 'italic', paddingTop: '20px' }}>
          {t('PLEASE_FILL_ALL_APARTMENTS_FIELDS')}
        </p>
        <div className='addressform-drawer-form' style={{ width: '100%' }}>
          <Row gutter={10} style={{ width: '100%' }}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
              <div className='addressform-drawer-apartement'>
                <Form.Item
                  name='apartment'
                  label={t('APARTMENT')}
                  initialValue={defaultAddress?.apartment}
                  rules={[
                    {
                      max: 20,
                      message: useCustomHtml({
                        keyValues: [
                          { key: '[LABEL]', value: t('APARTMENT') },
                        ],
                        inputString: t('INPUT_NUMBER_LIMITE_LENGTH'),
                      }),
                    },
                    {
                      pattern: INPUT_VALIDATION_REGEX,
                      message: t('INVALID_VALUE'),
                    },
                  ]}
                >
                  <Input id='adress-form-apartment' name='apartment' />
                </Form.Item>
              </div>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
              <div className='addressform-drawer-digicode'>
                <Form.Item
                  name='digicode'
                  label={t('DIGICODE')}
                  initialValue={defaultAddress?.digicode}
                  rules={[
                    {
                      type: 'string',
                      max: 20,
                      message: useCustomHtml({
                        keyValues: [
                          { key: '[LABEL]', value: t('DIGICODE') },
                        ],
                        inputString: t('INPUT_NUMBER_LIMITE_LENGTH'),
                      }),
                    },
                    {
                      pattern: INPUT_VALIDATION_REGEX,
                      message: t('INVALID_VALUE'),
                    },
                  ]}
                >
                  <Input id='adress-form-digicode' name='digicode' />
                </Form.Item>
              </div>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
              <div className='addressform-drawer-floor'>
                <Form.Item
                  name='floor'
                  label={t('FLOOR')}
                  initialValue={defaultAddress?.floor}
                  rules={[
                    {
                      pattern: /^(?=.{0,40}$).*/g,
                      message: t('FLOOR_LIMIT_LENGHT'),
                    },
                    {
                      type: 'number',
                      min: 0,
                      max: 40,
                      message: t('FLOOR_LIMIT_LENGHT'),
                    },
                    {
                      pattern: INPUT_VALIDATION_REGEX,
                      message: t('INVALID_VALUE'),
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    id='adress-form-floor'

                    name='floor'
                    controls={true}
                    type='number' />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <div className='addressform-drawer-elevator'>
            <Form.Item
              name="elevator"
              valuePropName="checked"
              initialValue={Boolean(defaultAddress?.elevator === 'false' ? false : defaultAddress?.elevator)}
            >
              <Checkbox id='checkbox-adress-form-elevator'>{t('ELEVATOR')}</Checkbox>
            </Form.Item>
          </div>
          <div className='addressform-drawer-additionnal-info'>
            <Form.Item
              name='additional_info'
              label={t('FURTHER_INFORMATIONS')}
              initialValue={defaultAddress?.additional_info}
              rules={[
                {
                  pattern: NO_EMOJI_REGEX,
                  message: t('INVALID_VALUE'),
                },
              ]}
            >
              <TextArea id='adress-form-additional-info' rows={5} showCount maxLength={255} allowClear />
            </Form.Item>
          </div>
        </div>
      </Row>
    </>
  );
}

export default AddressForm;
