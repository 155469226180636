import React, { useCallback, useContext } from 'react';
import UntouchableView from '../DeviceDetect/UntouchableView';
import { Button } from 'antd';
import { PlusSquareOutlined } from '@ant-design/icons';
import TouchableView from '../DeviceDetect/TouchableView';
import DrawersContext from '../../context/DrawersContext';
import NewAddressDrawer from '../Drawers/NewAddressDrawer';
import { NotificationCenterContext } from '../../context/NotificationCenterContext';
import { useFetch } from 'use-http';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../context/AppContext';

/**
 * OpenCreateAddressDrawer
 * @param {number} userId
 * @param {number} statusError
 * @param {function} setIndexValue
 * @param {function} setVisibleAddresses
 * @param {array} visibleAddresses
 * @param {function} setAddresses
 * @param {array} addresses
 * @param {object} deliveryAddress
 * @param {function} setDeliveryAddress
 * @param {function} showSaveBarCallback
 * @param {number} showMax
 * @return {JSX.Element}
 */
function CreateAddressButton( {
  userId, statusError,
  setIndexValue, setVisibleAddresses, visibleAddresses, setAddresses, addresses, deliveryAddress, setDeliveryAddress,
  showSaveBarCallback, showMax }) {
  const { t } = useTranslation();
  const [, dispatch] = useContext(AppContext);
  const showDrawer = useContext(DrawersContext)['setVisible'];
  const setCurrentDrawer = useContext(DrawersContext)['setCurrentDrawer'];
  const notification = useContext(NotificationCenterContext);
  const newAddressFetch = useFetch(process.env.REACT_APP_API_BO, { cachePolicy: 'no-cache' });

  const openNewAddressDrawer = () => {
    showDrawer(true);
    setCurrentDrawer(
      <NewAddressDrawer
        userId={userId}
        createAddressCallback={createAddressCallback}
      />,
    );
  };

  const createAddressCallback = useCallback(async(address) => {
    if (statusError === 200) {
      await newAddressFetch.post('/addresses', address);
      if (newAddressFetch.response.status === 200 ) {
        // Ajout de l'id de l'adresse dans l'objet de l'adresse afin de permettre sa modification et sa suppression
        setVisibleAddresses([{
          ...address,
          id: newAddressFetch.response.data,
        }, ...visibleAddresses]);
        setAddresses([{
          ...address,
          id: newAddressFetch.response.data,
        }, ...addresses]);
        notification.push('success', t('CREATE_ADDRESS'));
      } else {
        notification.push('error', t('INTERNAL_ERROR'));
      }
    } else {
      setIndexValue(0);
      if (deliveryAddress) {
        setVisibleAddresses([address, ...visibleAddresses].slice(0, showMax));
        setDeliveryAddress(address);
        showSaveBarCallback();
      } else {
        setAddresses([address, ...addresses]);
        dispatch({
          type: 'SET_CUSTOMER_SELECTED_ADDRESS',
          payload: address,
          isNewAddress: true,
        });
      }
    }
  });

  return (
    <>
      <UntouchableView>
        <Button icon={<PlusSquareOutlined />} onClick={openNewAddressDrawer}> {t('ADD_A_NEW_ADDRESS')}</Button>
      </UntouchableView>
      <TouchableView>
        <Button icon={<PlusSquareOutlined />} onClick={openNewAddressDrawer}/>
      </TouchableView>
    </>
  );
}

export default CreateAddressButton;
