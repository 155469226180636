import { Empty, Result } from 'antd';
import React from 'react';
import BigEmpty from '../../assets/shopopopEmpty.svg';
import { useTranslation } from 'react-i18next';

/**
 * ResultNoAdress
 * @return {JSX.Element}
  */
function ResultNoAdress() {
  const { t } = useTranslation();

  return (
    <Result
      icon={<Empty
        image={BigEmpty}
        description={t('DELIVERY_NEW_USER_NO_ADDRESS')}
      />}
      style={{ padding: 20 }}
    />
  );
}

export default ResultNoAdress;
