import { ReactNode } from 'react';
import { Steps, theme, StepsProps } from 'antd';
import styled from '@emotion/styled';

interface FullWidthInlineStepsProps extends StepsProps{
  colorborder: string;
}

/**
 * @param {{}} props
 * @return {ReactNode}
 */
function FullWidthInlineSteps(props: StepsProps): ReactNode {
  const { token } = theme.useToken();

  return (
    <FullWidthInlineStepsStyle
      {...props}
      colorborder={token.colorBorder}
      style={{ width: '305px', margin: '0 auto' }}
    />
  );
}

const PRE_TAILS_WIDTH = 18;
const FullWidthInlineStepsStyle = styled(Steps)<FullWidthInlineStepsProps>`
    position: relative;
    display: grid !important;
    grid-template-columns: repeat(${
      (props) => {
        return props.items?.length || 0;
      }}, 1fr);
    z-index: 1;

    &.ant-steps.ant-steps-inline .ant-steps-item-container {
      cursor: auto !important;
    }

    &.ant-steps-inline .ant-steps-item-tail::after {
        background-color: ${(props) => props.colorborder} !important;
    }

    .ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon .ant-steps-icon-dot {
        background-color: ${(props) => props.colorborder} !important;
        border-color: ${(props) => props.colorborder} !important;
    }

    .ant-steps-icon-dot {
        border-color: ${(props) => props.colorborder} !important;
    }
    
    .ant-steps-item:first-of-type .ant-steps-item-tail {
        width: calc(50% + ${PRE_TAILS_WIDTH}px) !important;
        margin-inline-start: calc(50% - ${PRE_TAILS_WIDTH}px) !important;
    }
    .ant-steps-item:last-of-type .ant-steps-item-tail {
        width: calc(50% + ${PRE_TAILS_WIDTH}px) !important;
    }
`;

export default FullWidthInlineSteps;
