/** @jsxImportSource @emotion/react */
import { React } from 'react';
import { Button, Select, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import mapI18N from '../../i18n/i18nAntD';
import { breakpoints } from '../../globalVar/theme';
import CountriesSelector from './CountriesSelector/CountriesSelector';
import { countryAlphaOrder } from '../../utils/countryAlphaOrder';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import useChangeLanguage from '../../hooks/useChangeLanguage';
import { useNavigate } from 'react-router-dom';

const { Text } = Typography;

/**
 * Settings
 * @return {string}
 */
function Settings() {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  useDocumentTitle(t('URL_SETTINGS'));
  const navigate = useNavigate();


  const inputStyle = {
    'width': '100%',
    [breakpoints.sm]: {
      maxWidth: '400px',
    },
  };

  const handleChangeLanguage = useChangeLanguage();

  const defaultValue = mapI18N.filter((lang) => lang.keys.includes(i18n.languages[0]))[0]?.name;

  return (
    <Space direction='vertical' size={20}>
      <Space direction='vertical' style={{ width: '100%' }}>
        <Text strong>{t('LANG')}</Text>
        <Select
          css={inputStyle}
          showSearch
          defaultValue={defaultValue}
          onSelect={handleChangeLanguage}
          options={countryAlphaOrder(mapI18N).map((lang) => {
            return ({
              value: lang.name,
              label: lang.name,
            });
          })
          }
        />
      </Space>
      <CountriesSelector inputStyle={inputStyle} />
      <Button
        type='primary'
        onClick={() => navigate('/login/forgot-password')}
      >
        {t('RESET_PASSWORD_PRO')}
      </Button>
    </Space>
  );
}

export default Settings;
