/** @jsxImportSource @emotion/react */
import React, { useEffect, useContext, useState, useCallback } from 'react';
import { Form, Button, Space, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import PersonalInformationsFieldset from './PersonalInformationsFieldset';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import useFetch from 'use-http';
import { StepContext } from '../CreateDeliveryForm';
import { NotificationCenterContext } from '../../../../context/NotificationCenterContext';
import UserSearch from '../../../User/UserSearch/UserSearch';
import ButtonCreateNewUser from './ButtonCreateNewUser';
import CardAdresses from '../../../Address/CardAdresses';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';
import AddressesListCard from '../../../Addresses/AddressesListCard';
import { readUserAddresses } from '../../../../services/apiEndPoints/addresses/userAddress/readUserAddresses';

/**
 * CreateDeliveryForm
 * @return {Form}
 */
function Step1({ store, dispatch }) {
  const { t } = useTranslation();
  useDocumentTitle(t('URL_DELIVERY_CREATION_RECIPIENT'));
  const [form] = Form.useForm();
  const { showCustomerForm, setShowCustomerForm, setCurrentStep, setStepsValidated } = useContext(StepContext);
  const notification = useContext(NotificationCenterContext);

  // TODO : A supprimer une fois que le composant CardAdresses sera supprimé,
  //  c'est-à-dire lorsqu'on aura réfléchi à la création d'une adresse pour un user non existant
  const [indexValue, setIndexValue] = useState(null);
  const [visibleAddresses, setVisibleAddresses] = useState(false);
  const [cardAdressesLegacy, setCardAdressesLegacy] = useState(false);
  // State adresses pour le composant AddressesListCard
  const [addresses, setAddresses] = useState([]);
  const [addressesLoading, setAddressesLoading] = useState(true);

  const addressFetch = useFetch(process.env.REACT_APP_API_BO, {
    cacheLife: parseInt(process.env.REACT_APP_LOCAL_CACHING_DATA, 10),
  });
  const distanceFetch = useFetch(process.env.REACT_APP_API_BO, {
    cacheLife: parseInt(process.env.REACT_APP_LOCAL_CACHING_DATA, 10),
  });
  const userFetch = useFetch((process.env.REACT_APP_API_BO, {
    cachePolicy: 'no-cache',
  }));

  useEffect(() => {
    setShowCustomerForm(store.delivery.customer.email?.length > 0 || store.delivery.customer.generateMail);
    setCurrentStep(0);
    setABTestNumber();
  }, []);

  const setABTestNumber = () => {
    dispatch({
      type: 'SET_ABTESTING_NUMBER', payload: {
        abTest: Math.trunc(Math.random() * 100),
      },
    });
  };

  // Récupérer les adresses de l'utilisateur
  useEffect(() => {
    if (store.delivery.customer.userId) {
      readUserAddresses({ userId: store.delivery.customer.userId }).then((response) =>{
        setAddresses(response.data);
        setAddressesLoading(false);
      });
    } else {
      setAddresses([]);
      setAddressesLoading(false);
      setCardAdressesLegacy(true);
    }
  }, [store.delivery.customer.userId]);

  // Modifier le contenu du store si je crée et/ou modifie une adresse
  useEffect(() => {
    dispatch({ type: 'SET_CUSTOMER_ADDRESSES', payload: [...addresses] });
    dispatch({ type: 'SET_CUSTOMER_SELECTED_ADDRESS', payload: { ...addresses[0] }});
  }, [addresses]);

  // Changer l'adresse de livraison
  const changeDeliveryAddress = useCallback((address) => {
    dispatch({ type: 'SET_CUSTOMER_SELECTED_ADDRESS', payload: { ...address }});
  }, []);

  // Récupérer la distance entre le point de départ et le point d'arrivée
  useEffect(() => {
    const fetchDistanceData = async() => {
      await distanceFetch.get(`/addresses/distance?originId=${store.delivery.customer.address.id}&destinationId=${store.delivery.drive.addressId}&tradeId=${store.delivery.drive.tradeId}`);
      if (distanceFetch.response.ok) {
        dispatch({ type: 'SET_ORDER_DISTANCE_INFOS', payload: {
          distance: distanceFetch.response.data.distance,
          isValidatedDistance: distanceFetch.response.data.isValidatedDistance,
        }});
      }
    };

    if (store.delivery.customer.address.id && store.delivery.drive.driveId) {
      fetchDistanceData();
    }
    if (!store.delivery.customer.address.id) {
      dispatch({ type: 'SET_ORDER_DISTANCE_INFOS', payload: {
        distance: 0,
      }});
    }
  }, [store.delivery.customer.address]);

  const resetEmailField = () => {
    form.setFieldsValue({
      email: '',
    });
  };

  const onFinish = async(values) => {
    if (Object.keys(store.delivery.customer.address).length === 0) {
      return notification.push('error', t('ADDRESS_IS_REQUIRED'));
    }

    let createdCustomerId;
    if (!store.delivery.customer.userId) {
      createdCustomerId = await createUser(values);
      if (!createdCustomerId) return;
    }

    const userAddress = {
      ...store.delivery.customer.address,
      user_id: store.delivery.customer.userId ? store.delivery.customer.userId : createdCustomerId,
      zip_code: store.delivery.customer.address.zip_code ? store.delivery.customer.address.zip_code : '',
      city: store.delivery.customer.address.city ? store.delivery.customer.address.city : '',
    };

    if (store.delivery.customer.address.id) {
      setCurrentStep(1);
      setStepsValidated([true, false, false]);
    } else {
      await addressFetch.post('/addresses', userAddress);
      if (addressFetch.error || addressFetch.response.status >= 400) {
        return notification.push('error', t('ERROR_OCCURED_ON_ADDRESS_CREATION'));
      } else {
        dispatch({ type: 'SET_CUSTOMER_ADDRESSES', payload: [{ ...userAddress, id: addressFetch.response.data }] });
        dispatch({ type: 'SET_CUSTOMER_SELECTED_ADDRESS', payload: { ...userAddress, id: addressFetch.response.data }});
        setCurrentStep(1);
        setStepsValidated([true, false, false]);
      }
    }
  };

  /**
   * createUser
   * @param {Object} values
   * @return {number}
   */
  async function createUser(values) {
    await userFetch.post('/users', {
      first_name: values.firstName,
      last_name: values.lastName,
      telephone: values.telephone,
      email: values.email,
      no_email: values.email.length === 0 ? true : false,
    });
    if (userFetch.response.ok) {
      notification.push('success', t('DELIVERY_USER_CREATED'));
      dispatch({ type: 'CREATE_DELIVERY_CUSTOMER', payload: {
        firstName: values.firstName,
        lastName: values.lastName,
        telephone: values.telephone,
        email: values.email,
        userId: userFetch.response.data,
        isExisting: true,
      }});
      form.setFieldsValue({
        isExisting: true,
      });

      return userFetch.response.data;
    } else {
      if (userFetch.response.status > 409) {
        notification.push('error', t('INTERNAL_ERROR'));
      }
      form.validateFields(['email']);
    }
  }

  const prev = () => {
    dispatch({ type: 'RESET_DELIVERY_FIELDS' });
    setShowCustomerForm(false);
    form.resetFields();
  };

  useEffect(() => {
    form.setFieldsValue({
      'apartment': store.delivery.customer.address?.apartment,
      'floor': store.delivery.customer.address?.floor,
      'digicode': store.delivery.customer.address?.digicode,
      'additional_info': store.delivery.customer.address?.additional_info,
    });
  }, [store.delivery.customer.address]);

  return (
    <>
      <Space
        direction='vertical'
        align='center'
        css={{
          display: showCustomerForm ? 'none' : 'flex',
          width: '100%',
          height: 'calc(100% - 46px)',
          paddingBottom: '20px',
        }}
      >
        <UserSearch inDeliveryCreation={true} formStep1={form} />
        <ButtonCreateNewUser formStep1={form}/>
      </Space>
      <Form
        name="CreateDeliveryForm"
        onFinish={onFinish}
        form={form}
        layout="vertical"
        css={{ display: showCustomerForm ? 'block' : 'none' }}
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <PersonalInformationsFieldset
              form={form}
              userFetch={userFetch}
              resetEmailField={resetEmailField}
              previousEmailValue={form.getFieldValue('email')}
            />
          </Col>
          <Col span={24}>
            {cardAdressesLegacy ?
              <CardAdresses
                userId={store.delivery.customer.userId}
                indexValue={indexValue}
                setIndexValue={setIndexValue}
                visibleAddresses={visibleAddresses}
                setVisibleAddresses={setVisibleAddresses}
                step1={true}
              /> : <AddressesListCard
                addresses={addresses}
                setAddresses={setAddresses}
                title='ADDRESSES'
                maxRows={2}
                actions={{
                  update: true,
                  create: true,
                  select: true,
                }}
                userId={store.delivery.customer.userId}
                addressesLoading={addressesLoading}
                selectOtherAddress={changeDeliveryAddress}
              />
            }
          </Col>
        </Row>

        <div css={{ display: 'flex', justifyContent: 'center', padding: '25px 0', gap: 100 }}>
          <Button icon={<ArrowLeftOutlined />} id='button-prev-create-step1' type="primary" htmlType="button" name="prev" onClick={() => prev()}>
            {t('PREVIEWS')}
          </Button>
          <Button id='button-next-create-step1'type="primary" onClick={() => form.submit()} name="next" loading={userFetch.loading || addressFetch.loading}>
            {t('NEXT')}
            <ArrowRightOutlined />
          </Button>
        </div>

      </Form>
    </>
  );
}
export default Step1;
