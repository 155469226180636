/** @jsxImportSource @emotion/react */
import { forwardRef, React, useImperativeHandle, useState } from 'react';
import { Button } from 'antd';
import { SaveOutlined, CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { colors } from '../../../globalVar/theme';

/**
 * Save Form
 * @param {object} form
 * @param {boolean} isLoading
 * @param {function} setDeliveryAddress
 * @param {object} delivery
 * @param {function} setSelectedDriveId
 * @param {function} setAddressErrorsInfos
 * @param {function} setPushAtUpdate
 * @param {function} resetAddressesList
 * @param {function} ref
 * @return {String}
 */
const Save = forwardRef(({ form, isLoading,
  setDeliveryAddress, delivery, setAddressErrorsInfos,
  setSelectedDriveId, setPushAtUpdate, resetAddressesList }, ref) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  useImperativeHandle(ref, () => ({
    showBar() {
      setShow(true);
    },
    hideBar() {
      setShow(false);
    },
  }));

  const resetAllFields = () => {
    form.resetFields();
    resetAddressesList();
    setDeliveryAddress(delivery.recipient.address);
    setShow(false);
    setSelectedDriveId(delivery.drive_id);
    setAddressErrorsInfos({});
    setPushAtUpdate(false);
  };

  const style = {
    bar: {
      'display': 'flex',
      'align-items': 'center',
      'position': 'sticky',
      'width': 'calc(100% + 40px)',
      'padding': '15px',
      'bottom': 20,
      'transform': 'translateX(-20px) translateY(20px)',
      'background-color': colors.primary,
      'z-index': 1,
      '@media (min-width: 821px)': {
        'justify-content': 'center',
        'padding': '10px 0',
        'border-radius': 8,
        'width': '100%',
        'left': 0,
        'bottom': 20,
        'margin': '20px 0 0 0',
        'transform': 'translateX(0) translateY(0)',
      },
    },
    saveButton: {
      backgroundColor: 'white',
      color: colors.primary,
      border: 'none',
    },
    cancelButton: {
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      right: 20,
      color: 'white',
      cursor: 'pointer',
      span: {
        marginRight: 5,
      },
    },
  };

  return show ? (
    <div css={style.bar}>
      <Button
        type='button'
        icon={<SaveOutlined />}
        size='large'
        id='button-save-detail-delivery'
        onClick={form.submit}
        loading={isLoading}
        css={style.saveButton}
      >
        {t('TO_SAVE')}
      </Button>
      <div
        id='button-cancel-detail-delivery'
        onClick={resetAllFields}
        css={style.cancelButton}
        className='white-anticon-close'
      >
        {<CloseOutlined />} {t('CANCEL')}
      </div>
    </div>
  ) : <></>;
});

export default Save;
