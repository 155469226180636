import { Card, Row, Col, Flex, Typography, Space, Timeline, theme } from 'antd';
import { useCustomHtml, useScreenSize } from '@shopopop/react-hooks';
import { useTranslation } from 'react-i18next';
import type { DeliveryInformationProps } from '../../interfaces/DeliveryInformationProps';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import styled from '@emotion/styled';

dayjs.extend(localizedFormat);
const { Text } = Typography;

function DeliveryInformationCard(deliveryInformation: Readonly<DeliveryInformationProps>) {
  const { t } = useTranslation();
  const { mobileMode } = useScreenSize();
  const { token: { colorPrimary }} = theme.useToken();

  // 🕑 Format the delivery date and hours
  const deliveryDate = dayjs(deliveryInformation.deliveryDate).format('D MMMM YYYY');
  const deliveryStartHour = dayjs(deliveryInformation.deliveryStartDate).locale(deliveryInformation.language ?? '').format('LT');
  const deliveryEndHour = dayjs(deliveryInformation.deliveryEndDate).locale(deliveryInformation.language ?? '').format('LT');

  return (
    <Card title={t('RECAP_DELIVERY_INFO_TITLE')}>
      <Row>
        <Col md={24} lg={12} style={{ width: '100%' }}>
          <Space style={{ padding: '6px 0' }}>
            <WaypointLine
              colorPrimary={colorPrimary}
              items={[
                {
                  children: deliveryInformation.originAddress,
                  dot: <DotWaypoint colorPrimary={colorPrimary} />,
                },
                {
                  children: deliveryInformation.destinationAddress,
                  dot: <DotWaypoint colorPrimary={colorPrimary} />,
                },
              ]}
            />
          </Space>
        </Col>
        <Col md={24} lg={12} style={{ width: '100%' }}>
          <Flex vertical gap={16}>
            <Space
              direction={mobileMode ? 'vertical' : 'horizontal'}
              style={{ rowGap: 0 }}
            >
              <Text type="secondary">{t('RECAP_DATE_TIMESLOT')} : </Text>
              <Text>
                {useCustomHtml({
                  inputString: t('RECAP_DELIVERY_DATE', {
                    deliveryDate,
                    deliveryStartHour,
                    deliveryEndHour,
                  }),
                  keyValues: [
                    {
                      key: '[deliveryDate]',
                      value: deliveryDate,
                    },
                    {
                      key: '[deliveryStartHour]',
                      value: deliveryStartHour ?? '',
                    },
                    {
                      key: '[deliveryEndHour]',
                      value: deliveryEndHour ?? '',
                    },
                  ],
                })}
              </Text>
            </Space>
            <Space style={{ rowGap: 0 }}>
              <Text type="secondary">{t('DELIVERY_DISTANCE')} : </Text>
              <Text>{deliveryInformation.formatedDistance}</Text>
            </Space>
          </Flex>
        </Col>
      </Row>
    </Card>
  );
}

const WaypointLine = styled(Timeline)<{ colorPrimary: string }>`
    .ant-timeline-item-tail {
        border-color: ${(props) => props.colorPrimary};
    }
    .ant-timeline-item-content {
        min-height: 0 !important;
    }
`;

const DotWaypoint = styled.div<{ colorPrimary: string }>`
  border: 2px solid ${(props) => props.colorPrimary};
  border-radius: 100%;
  width: 10px;
  height: 10px;
`;

export default DeliveryInformationCard;
