import { useState, useEffect } from 'react';
import { Tag } from 'antd';
import { fetchData } from './mock.ts';
import EnhancedTableOnDate from '../../Tables/common/EnhancedTableOnDate.tsx';

export interface Delivery {
  key: string;
  date: string; // ISO8601 string
  timeSlot: string;
  store: string;
  recipient: string;
  distance: string;
  status: string;
  isFirstOfDay: boolean;
}

function DateRowTable() {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  const [data, setData] = useState<Delivery[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    fetchData().then((fetchedData) => {
      setData(fetchedData);
      setLoading(false);
    });
  }, [currentPage, pageSize]);

  const handleTableChange = (pagination: any) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const columns = [
    {
      title: 'Créneaux',
      dataIndex: 'timeSlot',
      key: 'timeSlot',
    },
    {
      title: 'Magasin',
      dataIndex: 'store',
      key: 'store',
    },
    {
      title: 'Destinataire',
      dataIndex: 'recipient',
      key: 'recipient',
    },
    {
      title: 'Distance',
      dataIndex: 'distance',
      key: 'distance',
    },
    {
      title: 'Statut',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => (
        <Tag color={status === 'Disponible' ? 'green' : 'blue'}>
          {status}
        </Tag>
      ),
    },
  ];

  return (
    <EnhancedTableOnDate
      loading={loading}
      columns={columns}
      data={data}
      pagination={{ pageSize, current: currentPage }}
      onChange={handleTableChange}
    />
  );
}

export default DateRowTable;
