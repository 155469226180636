/**
 * @name addressFormatter
 * @param {object} address
 * @return {string}
 */
function addressFormatter(address) {
  if (address.original_address) {
    return address.original_address;
  } else {
    if (address.address && address.zip_code && address.city) return `${address.address}, ${address.zip_code} ${address.city}`;
    if (address.address && address.zip_code) return `${address.address}, ${address.zip_code}`;
    if (address.address && address.city) return `${address.address}, ${address.city}`;
    if (address.address) return `${address.address}`;
    if (address.zip_code && address.city) return `${address.zip_code} ${address.city}`;
    if (address.zip_code) return `${address.zip_code}`;
    if (address.city) return `${address.city}`;
    else return '';
  }
}

export default addressFormatter;
