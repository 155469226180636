import { ReactNode, useState } from 'react';
import { Card, Radio, Input, Form, Tooltip, theme, Row, Col } from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { NO_EMOJI_REGEX } from '@shopopop/backoffice-frontend-utils';

function TransportInfoCard(): ReactNode {
  const { t } = useTranslation();
  const { token } = theme.useToken();
  const [value, setValue] = useState<string | undefined>(undefined);

  const onChange = (e: any) => {
    setValue(e.target.value);
  };

  return (
    <Card title={t('CTP_INFO_TITLE')} extra={(
      <Tooltip title={t('TOOLTIP_CTP_INFO')}>
        <InfoCircleTwoTone twoToneColor={token.colorPrimary} />
      </Tooltip>
    )}>
      <Row>
        <Col xs={24} xl={12}>
          <Form.Item
            label={t('ORDER_TRANSPORTATION')}
            name="transport"
            rules={[
              {
                required: true,
                message: t('TRANSPORT_MODE_REQUIRED'),
              },
            ]}
          >
            <Radio.Group onChange={onChange} value={value} style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <Radio.Button value={'Bike'} style={{ flex: 1, textAlign: 'center' }}>
                {t('BIKE')}
              </Radio.Button>
              <Radio.Button value={'Car'} style={{ flex: 1, textAlign: 'center' }}>
                {t('CAR')}
              </Radio.Button>
              <Radio.Button value={'Truck'} style={{ flex: 1, textAlign: 'center' }}>
                {t('TRUCK')}
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label={t('ORDER_ADDITIONAL_INFO')}
        name="additionalInfos"
        rules={[
          {
            pattern: NO_EMOJI_REGEX,
            message: t('INVALID_VALUE'),
          },
        ]}
      >
        <Input.TextArea maxLength={255} showCount />
      </Form.Item>
    </Card>
  );
}

export default TransportInfoCard;
