import { forwardRef, ReactNode, useState, useImperativeHandle, ForwardedRef } from 'react';
import { Layout, Flex, Form } from 'antd';
import StepButtons from './modules/StepButtonsProps';
import FullWidthInlineSteps from '../../../styles/FullWidthInlineStepsStyle.styles';
import { StepFormLayoutProps } from '../../../interfaces/ChildrensStepsForm';
import { useTitle } from '@shopopop/react-hooks';

const { Content } = Layout;

interface StepButtonProps {
  prev?: {
    label: string;
    onClick?: () => void;
  };
  next?: {
    label: string;
    onClick?: () => void;
  };
}

interface StepFormLayoutRefCurrentType {
  prev: () => void;
  next: () => void;
}

/**
 * @return {ReactNode} StepFormLayout component
 */
const StepFormLayout = forwardRef(function StepFormLayoutRef({
  form,
  tabTitles,

  stepElements,

  showStepButtons,
  updateStatusStepInStore,
  loaders,
  checkValidityOnStep,
  handleSubmit,
  stepButtons,
}: StepFormLayoutProps & { stepButtons: StepButtonProps[] }, ref: ForwardedRef<StepFormLayoutRefCurrentType>): ReactNode {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [disabledButtons, setDisabledButtons] = useState<boolean>(false);

  // Update tab title on step change
  useTitle(tabTitles[currentStep]);

  // Expose the functions via the ref
  useImperativeHandle(ref, () => ({
    prev,
    next,
  }));

  const updateStep = (step: number, direction: 'prev' | 'next') => {
    if (direction === 'prev' && step > 0) {
      updateStatusStepInStore(step, 'wait');
      updateStatusStepInStore(step - 1, 'process');
      return step - 1;
    } else if (direction === 'next' && step < stepElements.length - 1) {
      updateStatusStepInStore(step, 'finish');
      updateStatusStepInStore(step + 1, 'process');
      return step + 1;
    }
    return step;
  };

  const prev = () => {
    setCurrentStep((prevStep) => updateStep(prevStep, 'prev'));
  };

  const next = () => {
    setDisabledButtons(true);
    if (form) {
      form.validateFields()
        .then((values) => {
          checkValidityOnStep(currentStep, values)
            .then((isValid) => {
              setDisabledButtons(false);
              if (isValid) {
                setCurrentStep((nextStep) => updateStep(nextStep, 'next'));
              }
            });
        })
        .catch((errorInfo) => {
          setDisabledButtons(false);
          console.error('errorInfo', errorInfo);
          updateStatusStepInStore(currentStep, 'error');
        });
    } else {
      setCurrentStep((nextStep) => updateStep(nextStep, 'next'));
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={() => next()}>
      <Flex
        vertical={true}
        gap={20}
        style={{
          width: '100%',
        }}
      >
        <FullWidthInlineSteps
          progressDot
          current={currentStep}
          direction={'horizontal'}
          type={'inline'}
          responsive={false}
          items={stepElements.map((item) => ({
            key: item.title,
            title: item.title,
            status: item.status,
          }))}
        />
        <Content>
          {stepElements[currentStep].description}
        </Content>
      </Flex>
      {showStepButtons && (
        <StepButtons
          disabledButtons={disabledButtons}
          isLoading={loaders.some((loader) => loader)}
          currentStep={Number(currentStep)}
          stepButtons={stepButtons.map((button, index) => {
            const stepButton: any = {}; // Utilisez un type plus spécifique si possible

            if (button.prev) {
              stepButton.prev = {
                ...button.prev,
                onClick: () => {
                  if (button.prev?.onClick) button.prev.onClick();
                  prev();
                },
              };
            }

            if (button.next) {
              stepButton.next = {
                ...button.next,
                onClick: () => {
                  if (button.next?.onClick) button.next.onClick();
                  if (index === stepButtons.length - 1) handleSubmit;
                  else next();
                },
              };
            }

            return stepButton;
          })}
        />
      )}
    </Form>
  );
});

export default StepFormLayout;
