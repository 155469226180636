/** @jsxImportSource @emotion/react */
import React, { useContext, useState } from 'react';
import { Select, Col, Row, Button, Drawer, Space, Form, AutoComplete, Input, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import AddressForm from '../Address/AddressForm';
import DrawersContext from '../../context/DrawersContext';
import countries from '../../i18n/countries.json';
import { useFetch } from 'use-http';
import { NotificationCenterContext } from '../../context/NotificationCenterContext';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { AppContext } from '../../context/AppContext';
import addressFormatter from '../../utils/addressFormatter';

/**
 * AddressDrawer
 * @param {Object} address
 * @return {Drawer}
 */
function AddressDrawer({ address, deleteAddressFromListView, updateAddressListView, disabledArchiveButton }) {
  const { t } = useTranslation();
  const visible = useContext(DrawersContext)['visible'];
  const setVisible = useContext(DrawersContext)['setVisible'];
  const [form] = Form.useForm();
  const addressFetch = useFetch(process.env.REACT_APP_API_BO);
  const notification = useContext(NotificationCenterContext);
  const [store] = useContext(AppContext);
  const [disabledSaveButton, setDisabledSaveButton] = useState(false);

  const archiveAddress = async() => {
    await addressFetch.delete(`/addresses/${address.id}`, {
      deleted_date: new Date(),
    });
    if (addressFetch.response.ok) {
      notification.push('success', t('ARCHIVE_ADRESS'));
      deleteAddressFromListView(address.id);
    } else {
      notification.push('error', t('error'));
    }
    setVisible(false);
  };

  const onFinish = async(values) => {
    const addressInfos = {
      floor: values.floor === null ? undefined : values.floor,
      apartment: values.apartment === null ? undefined : values.apartment,
      elevator: values.elevator === null ? undefined : values.elevator,
      digicode: values.digicode === null ? undefined : values.digicode,
      additional_info: values.additional_info === null ? undefined : values.additional_info,
    };

    if (address.id) {
      await addressFetch.put(`/addresses/${address.id}`, addressInfos);
      if (addressFetch.response.ok) {
        notification.push('success', t('UPDATING_ADDRESS'));
        updateAddressListView(addressFetch.response.data);
      }
      if (addressFetch.response.status >= 400) {
        notification.push('error', t(`UPDATING_ADDRESS_${addressFetch.response.statusText}`));
      }
      if (addressFetch.response.status === 500) {
        notification.push('error', t('INTERNAL_ERROR'));
      }
    }
    if (!address.id) {
      updateAddressListView({ ...address, ...addressInfos });
    }
    setVisible(false);
    form.resetFields();
  };

  return (
    (<Drawer
      title={t('MODIFY_AN_ADDRESS')}
      placement="right"
      width={window.innerWidth <= 630 ? '100%' : 630}
      open={visible}
      onClose={() => setVisible(false)}
      footer={
        <Space>
          <Popconfirm
            title={t('CONFIRM_ARCHIV_ADRESS')}
            onConfirm={archiveAddress}
            icon= {<ExclamationCircleFilled css={{ color: 'var(--terracotta)' }} />}
            disabled={disabledArchiveButton}
          >
            <Button
              danger
              type='primary'
              id='drawers-address-button-archive'
              disabled={disabledArchiveButton}
            >
              {t('ARCHIVE')}
            </Button>
          </Popconfirm>

          <Button
            type="primary"
            onClick={() => form.submit()}
            id='drawers-address-button-save'
            disabled={disabledSaveButton}
          >
            {t('SAVE')}
          </Button>
        </Space>
      }
    >
      <Form
        name="CreateDeliveryForm"
        form={form}
        layout="vertical"
        initialValues={{}}
        onFinish={onFinish}
        onFieldsChange={() => setDisabledSaveButton(form.getFieldsError().filter(({ errors }) => errors.length).length > 0)}
      >
        <Row style={{ backgroundColor: 'white' }}>
          <Col xs={24}>
            <Form.Item
              label={t('COUNTRY')}
              name="country"
              rules={[{ required: true, message: t('COUNTRY_REQUIERED') }]}
              initialValue={store.delivery?.customer?.address?.country?.length > 0 ?
                countries.filter((countrycode) => countrycode.alpha2 === store.delivery.customer.address.country)[0].code :
                countries[0].code}
            >
              <Select
                style={{ width: '100%' }}
                placeholder={t('SELECT_A_COUNTRY')}
                disabled={true}
                id='drawers-address-select-country'
                options={countries.map((country) => {
                  return ({
                    value: country.code,
                    label: country.name,
                  });
                })}
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              label={t('ADDRESS')}
              name="address"
              initialValue={addressFormatter(address)}
            >
              <AutoComplete
                style={{ width: '100%' }}
                disabled={true}
              >
                <Input.Search
                  name='userSearchInput'
                  id='drawer-address-user-search-input'
                />
              </AutoComplete>
            </Form.Item>
          </Col>
          <AddressForm defaultAddress={address} />
        </Row>
      </Form>
    </Drawer>)
  );
}

export default AddressDrawer;
