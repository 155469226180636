import Example from '../components/Example/Example';
import AddressesExample from '../components/Example/AddressesExample';
import Login from '../components/Login/Login';
import Home from '../components/Home/Home';
import Detail from '../components/Delivery/Detail/Detail';
import CreateDeliveryForm from '../components/Delivery/Create/CreateDeliveryForm';
import User from '../components/User/User';
import PageNotFound from '../components/PageNotFound/PageNotFound';
import Settings from '../components/Settings/Settings';
import ForgotPassword from '../components/Login/Auth/ForgotPassword';
import UserSearch from '../components/User/UserSearch/UserSearch';
import DeliveryListContainer from '../components/DeliveryList/DeliveryListContainer';

export const DEFAULT_ROUTE = '/deliveries?label=ongoing';
const DEFAULT_ELEMENT = <Home />;
const DEFAULT_ROLE = ['Shopopop - Contributors', 'Shopopop - Super Admin', 'Shopopop - Viewer'];

const routes = [
  {
    path: '/',
    title: 'MENU_HOME',
    // à remplacer par le composant App lorsqu'on aura la page d'accueil
    element: DEFAULT_ELEMENT,
    showMenu: true,
    roles: DEFAULT_ROLE,
  },
  {
    path: '/deliveries',
    title: 'MENU_DELIVERIES',
    element: <DeliveryListContainer />,
    showMenu: true,
    roles: DEFAULT_ROLE,
  },
  {
    path: '/example',
    title: 'Example',
    element: <Example />,
    showMenu: true,
    roles: DEFAULT_ROLE,
  },
  {
    path: '/example/addresses',
    title: 'Example-addresses',
    element: <AddressesExample />,
    protected: true,
    showMenu: true,
  },
  {
    path: '/example-b',
    title: 'Example B',
    element: <Example />,
    showMenu: true,
    roles: DEFAULT_ROLE,
  },
  {
    path: '/login',
    title: 'MENU_LOGIN',
    element: <Login />,
    protected: false,
    showMenu: false,
    roles: DEFAULT_ROLE,
  },
  {
    path: '/login/forgot-password',
    title: 'MENU_FORGOT_PASSWORD',
    element: <ForgotPassword />,
    protected: false,
    showMenu: false,
    roles: DEFAULT_ROLE,
  },
  {
    path: '/delivery',
    title: 'MENU_NEW_DELIVERY',
    element: <CreateDeliveryForm />,
    showMenu: true,
    roles: DEFAULT_ROLE,
  },
  {
    path: '/delivery/:id',
    title: 'MENU_DELIVERY',
    element: <Detail />,
    showMenu: true,
    roles: DEFAULT_ROLE,
  },
  {
    path: '/users',
    title: 'MENU_USER',
    element: <UserSearch />,
    showMenu: true,
    roles: DEFAULT_ROLE,
  },
  {
    path: '/users/:id',
    title: 'MENU_USER',
    element: <User />,
    showMenu: true,
    roles: DEFAULT_ROLE,
  },
  {
    path: '/settings',
    title: 'MENU_SETTINGS',
    element: <Settings />,
    showMenu: true,
    roles: DEFAULT_ROLE,
  },
  {
    path: '*',
    title: 'PAGE_NOT_FOUND',
    element: <PageNotFound />,
    protected: false,
    showMenu: false,
    roles: DEFAULT_ROLE,
  },
];

export default routes;
