import { Empty } from 'antd';
import BigEmpty from '../../assets/shopopop-empty.svg';

function EmptyNoResult() {
  return (
    <Empty
      image={String(BigEmpty)}
      imageStyle={{
        height: 41,
      }}
    />
  );
}

export default EmptyNoResult;
