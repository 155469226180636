/** @jsxImportSource @emotion/react */
import { Button, List } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import UntouchableView from '../DeviceDetect/UntouchableView';
import TouchableView from '../DeviceDetect/TouchableView';
import ItemAdress from './ItemAdress';
import ResultNoAdress from './ResultNoAdress';
import AddressDrawer from '../Drawers/AddressDrawer';
import { useCallback, useContext } from 'react';
import DrawersContext from '../../context/DrawersContext';
import { findAddress } from '../../utils/findAddress';

/**
 *  ListAddresses
 * @param {function} loadMore
 * @param {array} addresses
 * @param {function} setAddresses
 * @param {array} visibleAddresses
 * @param {function} setVisibleAddresses
 * @param {number} showMax
 * @return {JSX.Element}
 */
function ListAddresses({ loadMore, addresses, setAddresses, visibleAddresses, setVisibleAddresses, visibleAddressesCount }) {
  const { t } = useTranslation();
  const showDrawer = useContext(DrawersContext)['setVisible'];
  const setCurrentDrawer = useContext(DrawersContext)['setCurrentDrawer'];

  const updateAddressListView = useCallback((addressupdate)=> {
    setVisibleAddresses(visibleAddresses.map((address) => {
      if (address.id === addressupdate.id) {
        return addressupdate;
      }
      return address;
    }));
  });

  const deleteAddressFromListView = useCallback((addressId) => {
    const addressIndex = findAddress(addresses, addressId);
    const newAddresses = [
      ...addresses.slice(0, addressIndex),
      ...addresses.slice(addressIndex + 1),
    ];
    setAddresses(newAddresses);
    const newVisibleAddresses = addresses.filter((address) => address.id !== addressId);
    setVisibleAddresses(newVisibleAddresses.slice(0, visibleAddressesCount));
  });

  const openAddressDrawer = (address) => {
    showDrawer(true);
    setCurrentDrawer(
      <AddressDrawer
        address={address}
        updateAddressListView={(e) => updateAddressListView(e)}
        deleteAddressFromListView={(e) => deleteAddressFromListView(e)}
      />);
  };

  return (
    <List
      dataSource={visibleAddresses}
      loadMore={loadMore}
      size='small'
    >
      {visibleAddresses.length > 0 ? visibleAddresses.map((address, index) => (
        <List.Item key={index} actions={[<>
          <UntouchableView>
            <Button type='link' onClick={() => openAddressDrawer(address)} css={{ padding: 0 }}>
              {t('ACCESS_ADRESS_DETAILS')}
            </Button>
          </UntouchableView>
          <TouchableView>
            <Button icon={<EditOutlined />} onClick={() => openAddressDrawer(address)}/>
          </TouchableView>
        </>]}
        >
          <ItemAdress address={address}/>
        </List.Item>
      )) : <ResultNoAdress />}
    </List>
  );
}

export default ListAddresses;
