import { useCustomHtml, useScreenSize } from '@shopopop/react-hooks';
import { Badge, Card, Flex, theme } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeliveriesListCardProps } from '../../interfaces/DeliveriesListCardProps';
import styled from '@emotion/styled';

function DeliveriesListCard({ scheduledCount, inDeliveryCount, deliveredCount, interruptedCount, totalCount }: Readonly<DeliveriesListCardProps>) {
  const { t } = useTranslation();
  const { token: { colorPrimary, colorPrimaryBg, colorError, colorErrorBg, colorTextSecondary }} = theme.useToken();
  const { mobileMode } = useScreenSize();

  const [activeTabKey, setActiveTabKey] = useState<string>('scheduled');

  const extraTabMessage = useCustomHtml({
    inputString: t('DISPLAY_DELIVERY_DELIVERED_NBR', {
      deliveredCount,
      totalCount,
    }),
    keyValues: [
      {
        key: '[NBR_DELIVERY_DELIVERED]',
        value: deliveredCount.toString(),
      },
      {
        key: '[TOTAL_DELIVERIES]',
        value: totalCount.toString(),
        styles: `color:${colorTextSecondary}`,
      },
    ],
  });

  const onTabChange = (key: string) => {
    setActiveTabKey(key);
  };

  const tabList = [
    { key: 'scheduled', count: scheduledCount, label: t('DELIVERY_LIST_TAB1') },
    { key: 'inDelivery', count: inDeliveryCount, label: t('DELIVERY_LIST_TAB2') },
    { key: 'delivered', count: deliveredCount, label: t('DELIVERY_LIST_TAB3') },
    { key: 'interrupted', count: interruptedCount, label: t('DELIVERY_LIST_TAB4'), isError: true },
  ].map(({ key, count, label, isError }) => ({
    key,
    label: (
      <Flex gap={10} align='center'>
        {label}
        <Badge
          count={count}
          style={{
            backgroundColor: activeTabKey === key ? colorPrimary : (isError ? colorErrorBg : colorPrimaryBg),
            color: activeTabKey === key ? colorPrimaryBg : (isError ? colorError : colorPrimary),
          }}
          showZero={true}
        />
      </Flex>
    ),
  }));

  const contentList: Record<string, React.ReactNode> = {
    scheduled: <p>scheduled content</p>,
    inDelivery: <p>on delivery content</p>,
    delivered: <p>delivered content</p>,
    interrupted: <p>interrupted content</p>,
  };

  return (
    <StyledOnlyTabsCard>
      <Card
        style={{ width: '100%' }}
        tabList={tabList}
        activeTabKey={activeTabKey}
        tabBarExtraContent={!mobileMode && extraTabMessage}
        onTabChange={onTabChange}
        tabProps={{ tabBarGutter: 30, size: 'small' }}
      >
        {contentList[activeTabKey]}
      </Card>
    </StyledOnlyTabsCard>
  );
}

const StyledOnlyTabsCard = styled.div`
  .ant-card {
    .ant-tabs-nav-operations {
      display: none;
    }
  }
`;

export default DeliveriesListCard;
