/** @jsxImportSource @emotion/react */
import { theme, Button, List, Radio } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import UntouchableView from '../DeviceDetect/UntouchableView';
import TouchableView from '../DeviceDetect/TouchableView';
import ItemAdress from './ItemAdress';
import ResultNoAdress from './ResultNoAdress';
import { useCallback, useContext } from 'react';
import { AppContext } from '../../context/AppContext';
import DrawersContext from '../../context/DrawersContext';
import AddressDrawer from '../Drawers/AddressDrawer';
import { findAddress } from '../../utils/findAddress';

/**
 *  ListAddresses
 * @param {function} loadMore
 * @param {array} addresses
 * @param {array} visibleAddresses
 * @param {object} deliveryAddress
 * @param {function} setDeliveryAddress
 * @param {number} indexValue
 * @param {function} setIndexValue
 * @param {function} showSaveBarCallback
 * @param {array} updatedDeliveryList
 * @param {function} setUpdatedDeliveryList
 * @param {function} setAddresses
 * @param {function} setVisibleAddresses
 * @param {number} visibleAddressesCount
 * @param {object} addressErrorsInfos
 * @return {JSX.Element}
 */
function ListRadioAddresses({
  visibleAddressesCount,
  updatedDeliveryList,
  setUpdatedDeliveryList,
  loadMore,
  addresses,
  setAddresses,
  visibleAddresses,
  setVisibleAddresses,
  deliveryAddress,
  setDeliveryAddress,
  indexValue,
  setIndexValue,
  showSaveBarCallback,
  addressErrorsInfos,
}) {
  const { token } = theme.useToken();
  const { t } = useTranslation();
  const [, dispatch] = useContext(AppContext);
  const showDrawer = useContext(DrawersContext)['setVisible'];
  const setCurrentDrawer = useContext(DrawersContext)['setCurrentDrawer'];

  // Modification des informations complémentaires d'une adresse
  const updateAddressListView = useCallback((addressupdate)=> {
    setIndexValue(findAddress(visibleAddresses, addressupdate.id));
    setAddresses([
      ...addresses.slice(0, findAddress(addresses, addressupdate.id)),
      addressupdate,
      ...addresses.slice(findAddress(addresses, addressupdate.id) + 1)],
    );
    setVisibleAddresses([
      ...visibleAddresses.slice(0, findAddress(visibleAddresses, addressupdate.id)),
      addressupdate,
      ...visibleAddresses.slice(findAddress(visibleAddresses, addressupdate.id) + 1)],
    );
    if (deliveryAddress) {
      // Permet de mettre à jour l'adresse de livraison si c'est une nouvelle adresse ou une adresse différente de celle sélectionnée
      if (!addressupdate.id || deliveryAddress.id !== addressupdate.id) {
        showSaveBarCallback();
        setDeliveryAddress(addressupdate);
      }
    } else {
      // permet de mettre à jour le store si l'adresse modifiée est celle sélectionnée
      dispatch({
        type: 'SET_CUSTOMER_SELECTED_ADDRESS',
        payload: addressupdate,
      });
    }
  });

  // Suppression d'une adresse
  const deleteAddressFromListView = useCallback((addressId) => {
    const addressIndex = findAddress(addresses, addressId);
    if (deliveryAddress) {
      const newAddresses = [
        ...addresses.slice(0, addressIndex),
        ...addresses.slice(addressIndex + 1),
      ];
      setAddresses(newAddresses);
      const newVisibleAddresses = visibleAddresses.filter((address) => address.id !== addressId);
      setVisibleAddresses(newVisibleAddresses);
      const newUpdatedDeliveryList = updatedDeliveryList.filter((address) => address.id !== addressId);
      setUpdatedDeliveryList(newUpdatedDeliveryList);
      if (deliveryAddress && deliveryAddress.id === addressId) {
        setDeliveryAddress(null);
      }
    } else {
      dispatch({
        type: 'UPDATE_CUSTOMER_ADDRESSES',
        payload: [
          ...addresses.slice(0, addressIndex),
          ...addresses.slice(addressIndex + 1),
        ],
      });
      dispatch({
        type: 'RESET_CUSTOMER_SELECTED_ADDRESS',
        payload: addresses[indexValue],
      });
    }
  });

  const openAddressDrawer = (address, addressItemIndex) => {
    showDrawer(true);
    setCurrentDrawer(
      <AddressDrawer
        address={address}
        updateAddressListView={(e) => updateAddressListView(e)}
        deleteAddressFromListView={(e) => deleteAddressFromListView(e)}
        disabledArchiveButton={(deliveryAddress && (address?.id === deliveryAddress?.id)) || indexValue === addressItemIndex}
      />);
  };

  const changeIndex = (e) => {
    setIndexValue(e.target.value);
    if (deliveryAddress) {
      setDeliveryAddress(visibleAddresses[e.target.value]);
      showSaveBarCallback();
    }
  };
  const addressDistanceToLongStyle = {
    '.ant-radio .ant-radio-input + .ant-radio-inner': {
      'borderColor': 'red',
      '&::after': {
        borderColor: `${token.colorError}`,
        backgroundColor: `${token.colorError}`,
      },
    },
    '.ant-radio-checked .ant-radio-inner': {
      borderColor: `${token.colorError}`,
      backgroundColor: `${token.colorBgContainer}`,
    },
  };

  return (
    <List
      dataSource={visibleAddresses.slice(0, visibleAddressesCount)}
      loadMore={loadMore}
      size='small'
    >
      {visibleAddresses.length > 0 ? visibleAddresses.map((address, index) => (
        <List.Item key={index}
          actions={[
            <>
              <UntouchableView>
                <Button type='link' onClick={() => openAddressDrawer(address, index)} css={{ padding: 0 }}>
                  {t('ACCESS_ADRESS_DETAILS')}
                </Button>
              </UntouchableView>
              <TouchableView>
                <Button icon={<EditOutlined />} onClick={() => openAddressDrawer(address, index)} />
              </TouchableView>
            </>,
          ]}
        >
          <Radio.Group
            value={indexValue}
            onChange={changeIndex}
          >
            <Radio value={index}
              css={(addressErrorsInfos?.message && addressErrorsInfos?.address_id === address.id) && addressDistanceToLongStyle}
            />
          </Radio.Group>
          <ItemAdress addressErrorsInfos={addressErrorsInfos} address={address} indexValue={indexValue}/>
        </List.Item>
      )) :
        <ResultNoAdress />}
    </List>
  );
}

export default ListRadioAddresses;
