import { ReactNode } from 'react';
import { Layout, Typography } from 'antd';

const { Text } = Typography;

interface DeliveryPickupTupleProps {
  pickupPoint: string;
  zipCode: string;
  city: string;
}

function DeliveryPickupTuple({ pickupPoint, zipCode, city }: DeliveryPickupTupleProps): ReactNode {
  return (
    <Layout>
      <Text>{pickupPoint}</Text>
      <Text type="secondary">{zipCode} {city}</Text>
    </Layout>
  );
}

export default DeliveryPickupTuple;
